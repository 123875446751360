/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export enum QCSButtons {
  SHIFT_PROGRESS = 'SHIFT_PROGRESS',
  MY_PROGRESS = 'MY_PROGRESS',
}

export const GroupByOptions = [
  {
    label: 'FinalizationDashboard.GroupBy.PurchaseOrder',
    value: 'purchaseOrder',
  },
  {
    label: 'FinalizationDashboard.GroupBy.Locations',
    value: 'locations',
  },
];

export const PODiscrepancyAdjResons = {
  RECOUNT: { label: 'FinalizationDashboard.AdjReasons.Recount', code: '95000' },
  REBOX: { label: 'FinalizationDashboard.AdjReasons.Rebox', code: '99929' },
  RECEIVE: { label: 'FinalizationDashboard.AdjReasons.Receive', code: '99945' },
};

export const NUMERIC_REGEX = /^(\s*|\d+)$/;

export const DISCREPANCY_ACTION_STATE = {
  SAVE: 'SAVE',
  EDIT: 'EDIT',
};

export const DISCREPANCY_STATUS = {
  UNCHECKED: 'UNCHECKED',
  CHECKED: 'CHECKED',
};

export const MAX_TEXTAREA_LENGTH_NOTES_FOR_INBOUND = 1000;
export const MAX_TEXTAREA_LENGTH_REASON_FOR_REVERT_PO_STATUS = 1000;
export const MAX_PCS_INPUT_LENGTH = 20;

export const headersData = [
  {
    label: 'FinalizationDashboard.Table.ProductName',
    width: '20.6%',
  },
  {
    label: 'FinalizationDashboard.Table.Ordered',
    width: '7.7%',
  },
  {
    label: 'FinalizationDashboard.Table.Shipped',
    width: '11.2%',
  },
  {
    label: 'FinalizationDashboard.Table.Received',
    width: '9.0%',
  },
  {
    label: 'FinalizationDashboard.Table.Actual',
    width: '10.3%',
  },
  {
    label: 'FinalizationDashboard.Table.Difference',
    width: '7.7%',
  },
];

export const URLQuery = {
  GROUP_BY: 'groupBy',
  LOCATIONS: 'locations',
  PO: 'purchaseOrder',
};

export enum CountStatus {
  PAST = 'PAST',
  ACTIVE = 'ACTIVE',
}

export enum CHOOSE_ASSIGNEE_CONTENT_TYPE {
  SINGLE_ASSIGNEE = 'SingleAssignee',
  MULTIPLE_ASSIGNEE = 'MultipleAssignees',
}

export const MAX_VISIBLE_AVATARS_CYCLE_COUNT = 3;
export const SINGLE_PAGE_SIZE = 1;
export const PAGE = 0;
export const taskId = '12341234';
export const year = 2024;
export const parentLayoutDistinctName = 'SZ=FER1, ZN=FM';
export const userId = 'wdallas';
export const layoutDistinctNames = 'SZ=FER1, ZN=FM';
export const productId = 'SKU-123';
export const partNbr = 'SKU-123';

export const CYCLE_COUNT_TYPE_CD = {
  WALL_TO_WALL: 'WALL_TO_WALL',
  OPPORTUNITY: 'OPPORTUNITY',
  ADJUSTMENT: 'ADJUSTMENT',
  RISKBASED: 'RISKBASED',
  RECOUNT: 'RECOUNT',
  RBCC: 'RBCC',
};

export const CYCLE_COUNT_TABS = {
  WALL_TO_WALL: 'wall-to-wall',
};

export const CYCLE_COUNT_STATUS_CD = {
  NEW: 'NEW',
  INPROGRESS: 'INPROGRESS',
  COMPLETE: 'COMPLETE',
};

export const CYCLE_COUNT_ENTITY_ASSOCIATION = {
  COUNT_LAYOUT_SUBZONE: 'COUNT_LAYOUT_SUBZONE',
  COUNT_ASSIGNED_USER: 'COUNT_ASSIGNED_USER',
  COUNT_LAYOUT_LOCATION: 'COUNT_LAYOUT_LOCATION',
  COUNT_PERFORM_ACTION: 'COUNT_PERFORM_ACTION',
};
/* location count command handler sync API required constants */
export const REASON_CODE = {
  PICK_SHORT: 'PICK_SHORT',
  INBOUND_EXCEPTION: 'INBOUND_EXCEPTION',
  OUTBOUND_EXCEPTION: 'OUTBOUND_EXCEPTION',
  INVENTORY_MOVE: 'INVENTORY_MOVE',
  REPL_MOVE: 'REPL_MOVE',
};

export const INV_ADJUSTMENT_TYPE = {
  COMPLETE: 'COMPLETE',
  PARTIAL: 'PARTIAL',
  SKIP: 'SKIP',
};

export const INV_ADJUSTMENT_STATUS = {
  EXISTING: 'EXISTING',
  NEW: 'NEW',
};

export const ProgressCardFilters: { value: string; label: string }[] = [
  { value: 'THIS_WEEK', label: 'CycleCount.CombinedPillTabs.ThisWeek' },
  { value: 'P3', label: 'CycleCount.CombinedPillTabs.P3' },
];

export const MATRICS = [
  'VARIANCES_COUNTED',
  'DOLLAR_VARIANCE_OVER_THRESHOLD',
  'PIECE_ADJUSTMENTS_OVER_THRESHOLD',
  'LOCATION_COUNTS',
  'ACCURACY_MEASUREMENTS',
];

export const DOLLAR_VARIANCE_THRESHOLD = 250;
export const QTY_VARIANCE_THRESHOLD = 250;
export const DEFAULT = 'DEFAULT';
export const SUBZONE_ENTITY_CD = 'SZ';

export const AccuracyMeasurementCardFilters: { value: string; label: string }[] = [
  { value: 'THIS_WEEK', label: 'CycleCount.CombinedPillTabs.ThisWeek' },
  { value: 'LAST_FOUR_WEEKS', label: 'CycleCount.CombinedPillTabs.Last4Weeks' },
  { value: 'P3', label: 'CycleCount.CombinedPillTabs.P3' },
  { value: 'P2', label: 'CycleCount.CombinedPillTabs.P2' },
  { value: 'P1', label: 'CycleCount.CombinedPillTabs.P1' },
  { value: 'FISCAL YEAR', label: 'CycleCount.CombinedPillTabs.FiscalYear' },
];

export const TOP_VARIANCE_PILLS = [
  {
    value: 'TopVariances.ProductCategory.TabPills.ThisWeek',
    label: 'TopVariances.ProductCategory.TabPills.ThisWeek',
  },
  {
    value: 'TopVariances.ProductCategory.TabPills.Last4Weeks',
    label: 'TopVariances.ProductCategory.TabPills.Last4Weeks',
  },
  {
    value: 'TopVariances.ProductCategory.TabPills.P3',
    label: 'TopVariances.ProductCategory.TabPills.P3',
  },
  {
    value: 'TopVariances.ProductCategory.TabPills.P2',
    label: 'TopVariances.ProductCategory.TabPills.P2',
  },
  {
    value: 'TopVariances.ProductCategory.TabPills.P1',
    label: 'TopVariances.ProductCategory.TabPills.P1',
  },
  {
    value: 'TopVariances.ProductCategory.TabPills.FiscalYear',
    label: 'TopVariances.ProductCategory.TabPills.FiscalYear',
  },
];
// Used this enum for update subzone assignee
export enum USER_STATUS_CD {
  ASSIGNED = 'ASSIGNED',
}
export enum SUBZONE_ATTRIBUTE_KEY {
  SLOT_COUNT = 'SLOT_COUNT',
  RSRV_COUNT = 'RSRV_COUNT',
}
