/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Button } from '@az/starc-ui';
import { TabsFilterDataProps } from './CombinedPillTabs.types';

export const CombinedPillTabs = ({ TabsFilterData }: TabsFilterDataProps) => {
  const [selectedTab, setSelectedTab] = useState(TabsFilterData[0]);
  const { t } = useTranslation();

  return (
    <View
      gap={2}
      direction="row"
      align="center"
      attributes={{ 'data-testid': 'st-combined-pill-tabs' }}
    >
      {TabsFilterData.map((filter) => (
        <View.Item>
          <Button
            onClick={() => setSelectedTab(filter)}
            variant={selectedTab.value === filter.value ? 'pill' : 'pill-secondary'}
            attributes={{ style: { height: 'var(--st-unit-9)' } }}
          >
            {t(filter.label)}
          </Button>
        </View.Item>
      ))}
    </View>
  );
};
