/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { t } from 'i18next';
import { isNumeric } from '@shared/utils/validationUtils.ts';
import { MAX_LENGTH_FIELD } from '@mdm/constants/constants.ts';

const dimensionsSchema = z.object({
  height: z.number(),
  width: z.number(),
  length: z.number(),
  dimensionUomCode: z.string(),
  volume: z.number(),
  volumeUomCode: z.string(),
  weight: z.number(),
  weightUomCode: z.string(),
  packCode: z.string(),
  packSizeQty: z.number(),
  packSizeUomCode: z.string(),
  palletLayerQty: z.number(),
  palletHeightQty: z.number(),
  palletStackLimitQty: z.number(),
  conversionFactorQty: z.number(),
  shippingUnitWeightQty: z.union([z.number(), z.string()]),
  shippingUnitWeightUomCode: z.string(),
  shippingUnitPackCode: z.string(),
  shippingUnitPackSizeQty: z.number(),
  shippingUnitPackSizeUomCode: z.string(),
  customAttributesText: z.string(),
  shippingUnitPackDesc: z.string(),
});

const productVendorSchema = z.object({
  vendorId: z.string(),
  vendorIdReturn: z.string(),
  vendorIdRecall: z.string(),
  vendorIdWarranty: z.string(),
  vendorStatusCode: z.string(),
  vendorStatusReasonDesc: z.string(),
  isVendorRestricted: z.boolean(),
  vendorUpcNbr: z.string(),
  vendorCaseUpcNbr: z.string(),
  vendorEffectiveDate: z.string(),
  vendorExpiryDate: z.string(),
  dimensions: dimensionsSchema,
});

export const WarehouseProductCodesSchema = z.object({
  productCodeType: z.string().nullable(),
  productCodeValue: z.string().nullable(),
  productCodeScanQty: z.string().nullable(),
  productCodeSource: z.string().nullable(),
});

export const WarehouseProductSchema = z.object({
  productId: z.string(),
  partNumber: z.string(),
  prodDesc: z.array(
    z.object({
      prodDesc: z.string().nullable(),
      descLocale: z.string().nullable(),
    })
  ),
  imageUrl: z.string().nullable(),
  category: z
    .object({
      categoryName: z.string().nullable(),
      subCategoryName: z.string().nullable(),
      categoryDesc: z.string().nullable(),
    })
    .nullable(),
  safety: z
    .object({
      tempBandName: z.string().nullable(),
      isHazardousMaterial: z.string().nullable(),
      hazardCd: z.string().nullable(),
      flammableCd: z.string().nullable(),
      disposalCd: z.string().nullable(),
      recycleCd: z.string().nullable(),
      materialSafetyDataSheetDate: z.string().nullable(),
      isConveyableFlag: z.string().nullable(),
      productLifeDaysQty: z.string().nullable(),
      shelfLifeDaysQty: z.number().nullable(),
      maxReceivingExpiryDaysQty: z.string().nullable(),
      minReceivingExpiryDaysQty: z.string().nullable(),
    })
    .nullable(),
  codes: z.array(WarehouseProductCodesSchema).nullable(),
  physicalAttributes: z
    .array(
      z.object({
        physicalAttribType: z.string().nullable(),
        height: z.number().nullable(),
        width: z.number().nullable(),
        length: z.number().nullable(),
        dimensionUOM: z.string().nullable(),
        weightQty: z.number().nullable(),
        weightUOM: z.string().nullable(),
        volumeQty: z.number().nullable(),
        volumeUOM: z.string().nullable(),
        colorCode: z.string().nullable(),
        styleOfCode: z.string().nullable(),
      })
    )
    .nullable(),
  physicalPackingUnits: z
    .object({
      packSizeQty: z.string().nullable(),
      packSizeUomCd: z.string().nullable(),
      unitPerCaseQty: z.string().nullable(),
      unitPerTierQty: z.string().nullable(),
      tiersPerPalletQty: z.string().nullable(),
    })
    .nullable(),
  uomConversion: z
    .object({
      minOrderQty: z.number().nullable(),
      maxOrderQty: z.number().nullable(),
      whseInnerQty: z.number().nullable(),
      whseOuterQty: z.number().nullable(),
      whseCalculatedInnerQty: z.number().nullable(),
      conversionRatio: z.number().nullable(),
    })
    .nullable(),
  productFacilityAttributes: z
    .object({
      maxLanesQty: z.number().nullable(),
      maxStackingQty: z.number().nullable(),
      isCubescannedFl: z.string().nullable(),
      toteCompartmentSizeCd: z.string().nullable(),
      loadUnitCapacityQty: z.union([z.number(), z.string()]).nullable(),
      whseProductStatusCd: z.string().nullable(),
      currentBaseCostAmt: z.number().nullable(),
      currentCoreCostAmt: z.number().nullable(),
    })
    .nullable(),
  productVendors: z.array(productVendorSchema),
});

export const WarehouseProductViewSchema = z.object({
  productId: z.string(),
  partNumber: z.string(),
  prodDesc: z.array(
    z.object({
      prodDesc: z.string().nullable(),
      descLocale: z.string().nullable(),
    })
  ),
  imageUrl: z.string().nullable(),
  category: z
    .object({
      categoryName: z.string().nullable(),
      subCategoryName: z.string().nullable(),
      categoryDesc: z.string().nullable(),
    })
    .nullable(),
  safety: z
    .object({
      tempBandName: z.string().nullable(),
      isHazardousMaterial: z.string().nullable(),
      hazardCd: z.string().nullable(),
      flammableCd: z.string().nullable(),
      disposalCd: z.string().nullable(),
      recycleCd: z.string().nullable(),
      materialSafetyDataSheetDate: z.string().nullable(),
      isConveyableFlag: z.string().nullable(),
      productLifeDaysQty: z.string().nullable(),
      shelfLifeDaysQty: z.number().nullable(),
      maxReceivingExpiryDaysQty: z.string().nullable(),
      minReceivingExpiryDaysQty: z.string().nullable(),
    })
    .nullable(),
  codes: z.array(WarehouseProductCodesSchema).nullable(),
  physicalAttributes: z
    .array(
      z.object({
        physicalAttribType: z.string().nullable(),
        height: z.number().nullable(),
        width: z.number().nullable(),
        length: z.number().nullable(),
        dimensionUOM: z.string().nullable(),
        weightQty: z.number().nullable(),
        weightUOM: z.string().nullable(),
        volumeQty: z.number().nullable(),
        volumeUOM: z.string().nullable(),
        colorCode: z.string().nullable(),
        styleOfCode: z.string().nullable(),
      })
    )
    .nullable(),
  physicalPackingUnits: z
    .object({
      packSizeQty: z.string().nullable(),
      packSizeUomCd: z.string().nullable(),
      unitPerCaseQty: z.string().nullable(),
      unitPerTierQty: z.string().nullable(),
      tiersPerPalletQty: z.string().nullable(),
    })
    .nullable(),
  uomConversion: z
    .object({
      minOrderQty: z.number().nullable(),
      maxOrderQty: z.number().nullable(),
      whseInnerQty: z.number().nullable(),
      whseOuterQty: z.number().nullable(),
      whseCalculatedInnerQty: z.number().nullable(),
      conversionRatio: z.number().nullable(),
    })
    .nullable(),
  productFacilityAttributes: z
    .object({
      maxLanesQty: z.number().nullable(),
      maxStackingQty: z.number().nullable(),
      isCubescannedFl: z.string().nullable(),
      toteCompartmentSizeCd: z.string().nullable(),
      loadUnitCapacityQty: z
        .union([z.number(), z.string()])
        .superRefine((data, ctx) => {
          if (data && !isNumeric(data.toString())) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('Form.NumericError'),
            });
          }

          if (data && data.toString().length > MAX_LENGTH_FIELD.PRODUCT_LOAD_UNIT_CAPACITY) {
            ctx.addIssue({
              code: z.ZodIssueCode.too_big,
              maximum: MAX_LENGTH_FIELD.PRODUCT_LOAD_UNIT_CAPACITY,
              type: 'string',
              inclusive: true,
              message: t('Form.MaxCharacterAmount', {
                count: MAX_LENGTH_FIELD.PRODUCT_LOAD_UNIT_CAPACITY,
              }),
            });
          }
        })
        .nullable(),
      whseProductStatusCd: z.string().nullable(),
      currentBaseCostAmt: z.number().nullable(),
      currentCoreCostAmt: z.number().nullable(),
    })
    .nullable(),
  productVendors: z.array(productVendorSchema),
});
