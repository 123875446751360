/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View } from '@az/starc-ui';
import { CycleCountTable } from '../CycleCountTable/CycleCount.table';
import { useCycleCount } from '@inventory/services/hooks/useCycleCounts';
import { CycleCountRowType } from '@inventory/types/types';
import { useEffect, useMemo, useState } from 'react';
import { DEFAULT_PAGE, PAGE_SIZE, SORT_BY, SORT_ORDER } from '@shared/constants/constants';
import { mapCycleCountAPIData } from '@inventory/utils/table/cycleCountTableUtils';
import {
  CYCLE_COUNT_ENTITY_ASSOCIATION,
  CYCLE_COUNT_STATUS_CD,
} from '@inventory/constants/constants';
import { DIRECTION, SortRowsParam } from '@shared/components/Table/Table.types';
import { useNavigate } from 'react-router-dom';
import { PAGE_URLS } from '@shared/constants/routes';

type PastCountTableProps = {
  countTypeCd: string;
};

export const PastCountTable: React.FC<PastCountTableProps> = ({ countTypeCd }) => {
  const navigate = useNavigate();
  const [pastData, setPastData] = useState<CycleCountRowType[]>();
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);
  const [, setSortBy] = useState<string>('');
  const [, setDirection] = useState<string>(DIRECTION.ASCENDING);

  /* Query for past Count data */
  const { wallToWallData: PastCycleCountData, isLoading } = useCycleCount({
    searchPage: {
      page: currentPage - 1,
      size: PAGE_SIZE,
      sort: [
        {
          sortBy: SORT_BY.END_TS,
          direction: SORT_ORDER.DESC,
        },
      ],
    },
    searchCriteria: {
      countRequest: {
        countTypeCd: countTypeCd,
        statusCd: [CYCLE_COUNT_STATUS_CD.COMPLETE],
      },
      entitiyAssociations: [
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_SUBZONE,
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_ASSIGNED_USER,
      ],
    },
  });

  // Mapped PastCycleCountData using Location count search API Response
  const currentPastData: CycleCountRowType[] = useMemo(() => {
    return mapCycleCountAPIData(PastCycleCountData?.content, [CYCLE_COUNT_STATUS_CD.COMPLETE]);
  }, [PastCycleCountData]);

  const handleTableRowClick = (clickedRow: SortRowsParam) => {
    const { id } = clickedRow;
    const data = PastCycleCountData?.content.find((item) => item.taskId === id);
    localStorage.setItem('cycleCountPhaseData', JSON.stringify(data));
    navigate(PAGE_URLS.CYCLE_COUNTS_DETAILS(id));
  };

  /*  Hooks */
  useEffect(() => {
    if (currentPastData) {
      // set total pages based on API totalPages
      setTotalPage(PastCycleCountData?.totalPages || 0);
      setPastData(currentPastData);
    }
  }, [currentPastData, PastCycleCountData]);

  return (
    <>
      <View.Item>
        <CycleCountTable
          data={pastData}
          isLoading={isLoading}
          noRecordsLabel="CycleCount.PastTable.NoRecords"
          label="CycleCount.PastTable.Title"
          isPaginated={true}
          totalPage={totalPage}
          currentPage={currentPage}
          setOnPageChange={(pageNumber: number) => setCurrentPage(pageNumber)}
          setSortBy={(sorting, columnID) => {
            sorting.forEach((sort) => {
              if (sort.id === columnID) {
                setSortBy(columnID);
                setDirection(sort.direction);
              }
            });
          }}
          handleTableRowClick={(clickedRow) => handleTableRowClick(clickedRow)}
        />
      </View.Item>
    </>
  );
};
