/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { Button, Icon, SearchBar, Select, Skeleton, Text, useDebounce, View } from '@az/starc-ui';
import { Table } from '@shared/components/Table/Table.tsx';
import {
  PRODUCT_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants.ts';

import {
  DEBOUNCE_TIMER,
  DEFAULT_PAGE,
  ENTITY_ASSOCIATIONS,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
  PRODUCT_ID_TYPE,
} from '@shared/constants/constants.ts';

import { useTranslation } from 'react-i18next';
import { KEY, KEY_CODE } from '@shared/constants/keyConstants.ts';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import styles from '@mdm/pages/RDM/LocationManager/Location/LocationList.module.scss';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import {
  DEFAULT_PRODUCT_LIST_SORT,
  PRODUCT_LIST_SEARCH_OPTIONS,
  SEARCH_MENU_MAX_HEIGHT,
} from '@mdm/constants/constants.ts';
import { Download } from '@shared/assets/icons';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useSessionStorage } from '@shared/hooks/useStorage.ts';
import { SESSION_DC_ID_KEY } from '@shared/constants/storageConstants.ts';
import { useGetWarehouseProducts } from '@shared/services/hooks/useGetWarehouseProducts.ts';
import { mapProductTableRows } from '@mdm/utils/table/tableUtils.tsx';
import {
  DIRECTION as TABLE_SORT_DIRECTION,
  TableSorting,
} from '@shared/components/Table/Table.types.ts';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { useNavigate } from 'react-router-dom';
import { WarehouseProductType } from '@shared/types/schema.type.ts';

export const ProductList = () => {
  /* State */
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQueryValue = useDebounce(searchQuery, DEBOUNCE_TIMER);
  const [value, setValue] = useState<string>('');
  const breadcrumbs = useBreadcrumb();
  const [selectedDC] = useSessionStorage<string>(SESSION_DC_ID_KEY);
  const [searchFilter, setSearchFilter] = useState(PRODUCT_ID_TYPE.PRODUCT_ID);
  const [sortDirection, setSortDirection] = useState<string>(TABLE_SORT_DIRECTION.ASCENDING);
  const [sortColumnId, setSortColumnId] = useState<string>(DEFAULT_PRODUCT_LIST_SORT);

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const navigate = useNavigate();

  const { productsSearchData, isFetching: isLoading } = useGetWarehouseProducts(
    {
      productIdType: searchFilter,
      products: [debouncedSearchQueryValue || ''],
      currentPage: currentPage - 1,
      pageSize: PAGE_SIZE,
      sortBy: sortColumnId,
      direction: sortDirection,
      entityAssociations: [
        ENTITY_ASSOCIATIONS.CATEGORY,
        ENTITY_ASSOCIATIONS.SAFETY,
        ENTITY_ASSOCIATIONS.PRODUCT_CODES,
        ENTITY_ASSOCIATIONS.PHYSICAL_ATTRIBUTE,
        ENTITY_ASSOCIATIONS.PHYSICAL_PACKING_UNITS,
        ENTITY_ASSOCIATIONS.WHSE_UOM_CONVERSION,
        ENTITY_ASSOCIATIONS.WHSE_ATTRIBS,
      ],
    },
    !!debouncedSearchQueryValue
  );

  /* Function */
  const onViewDetails = (product: WarehouseProductType) => {
    navigate(PAGE_URLS.PRODUCT_DETAILS(product.productId));
  };

  const onSort = (mappedSorting: TableSorting[], columnID: string) => {
    const foundColumn = mappedSorting.find((column) => column.id === columnID);
    if (foundColumn) {
      setSortDirection(foundColumn.direction);
      setSortColumnId(foundColumn.id);
    }
  };

  const setSearchParam = (param: string) => {
    setSearchQuery(param);
  };

  const onKeyDown = (e: {
    key: string;
    keyCode: number;
    preventDefault: () => void;
    currentTarget: { value: string };
  }) => {
    if (e.key === KEY.ENTER || e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      const inputValue = (e.currentTarget as HTMLInputElement).value;
      setSearchParam(inputValue);
    }
  };

  const onChange = (e: { target: { value: string } }) => {
    const inputValue = e.target.value;
    if (!inputValue) {
      onClearSearch();
    }
  };

  const onClearSearch = () => {
    setSearchParam('');
    setValue('');
  };

  /* Hooks */
  useEffect(() => {
    if (productsSearchData) {
      if (productsSearchData.content.length === 0) {
        handleNotification(NOTIFICATION_TYPES.ERROR, t('Empty.Search.NoRecordsFound'));
      }
    }
  }, [handleNotification, t, productsSearchData]);

  const SubTitle = () => {
    return (
      <View direction="row" align="center" gap={4}>
        <View.Item>
          <Text size="100" color="600">
            {`${t('TotalProduct')}: `}
            {isLoading ? (
              <Skeleton className={styles['product__count-wrapper']} width="100px" height="24px" />
            ) : debouncedSearchQueryValue ? (
              productsSearchData?.totalElements || 0
            ) : (
              0
            )}
          </Text>
        </View.Item>
      </View>
    );
  };

  return (
    <View>
      <View className={styles['product-list']}>
        <MasterTitle
          title={t('MasterTitle.Product')}
          breadcrumbProps={breadcrumbs}
          titleActionProps={{ label: t('Favorite'), handleClick: () => ({}) }}
          subtitle={<SubTitle />}
        >
          <View direction="row" justify="end" align="center" gap={4}>
            <View.Item columns={6}>
              <SearchBar
                value={value}
                onValueChange={setValue}
                suggestions={[]}
                className={styles['search-bar']}
                label={t('Search.ProductList')}
                onValueClear={onClearSearch}
                inputAttributes={{ onKeyDown: onKeyDown, onChange: onChange }}
                maxMenuHeight={SEARCH_MENU_MAX_HEIGHT}
              />
            </View.Item>
            <View.Item
              columns={2}
              attributes={{
                'data-testid': 'search-filter',
              }}
            >
              <Select
                label=""
                variant="no-label"
                name="searchFilter"
                value={{
                  label:
                    PRODUCT_LIST_SEARCH_OPTIONS.find((option) => option.value === searchFilter)
                      ?.label || '',
                  value: searchFilter || '',
                }}
                options={PRODUCT_LIST_SEARCH_OPTIONS}
                onValueChange={(value) => setSearchFilter(value?.value || '')}
              />
            </View.Item>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button variant="secondary" size="large">
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Icon svg={Download} />
                      <Text>{t('Download')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
          </View>
        </MasterTitle>

        <View padding={[4, 6]}>
          <Table
            columns={PRODUCT_TABLE_COLUMNS}
            rows={mapProductTableRows(productsSearchData?.content || [], onViewDetails)}
            isPaginated={true}
            isCheckboxDisabled={false}
            pageSize={PAGE_SIZE}
            defaultPage={DEFAULT_PAGE}
            isCreditItem={false}
            isCheckboxTable={false}
            isApiLoadedData={true}
            isLoading={isLoading}
            styleVariant={TableStylingVariants.DETAILS}
            showEmptyState={true}
            emptyStateSubtitle={t('Table.ProductList.EmptyStateSubtitle', {
              DC: `${selectedDC}`,
            })}
            emptyStateText={t('Table.ProductList.EmptyStateText')}
            totalPages={productsSearchData?.totalPages || 0}
            onPageChange={(newPage) => {
              setCurrentPage(newPage);
            }}
            onSort={onSort}
          />
        </View>
      </View>
    </View>
  );
};
