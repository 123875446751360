/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Dropdown, View, Divider, Text, SearchBar, Actionable } from '@az/starc-ui';

import { KEY } from '@shared/constants/keyConstants';

import * as T from './LocationSelect.types';
import styles from './LocationSelect.module.scss';
import { useGetLayoutsProducts } from '@inbound/services/hooks/useGetLayoutProducts';
import { LayoutProductListType } from '@inbound/types/types';
import { DetailsTableSkeleton } from '@shared/components/Skeletons/DetailsTableSkeleton';

export const LocationSelect = ({ searchable, selectedLocation, onSelect }: T.Props) => {
  /* State variables */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [prevSearchValue, setPrevSearchValue] = useState<string>('');
  const [layoutProducts, setLayoutProducts] = useState<LayoutProductListType>([]);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  /* Constants */
  const { t } = useTranslation();

  /* Queries */
  const { isFetching: isLoading, refetch: getProducts } = useGetLayoutsProducts(
    {
      productId: searchValue,
      layoutName: searchValue,
      entityAssociations: [],
    },
    searchValue.length >= 3 && prevSearchValue.substring(0, 3) !== searchValue.substring(0, 3)
  );

  /* Functions */
  const toggleDropdown = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const onLocationSelect = (location: T.DropdownOption) => {
    onSelect(location);
    setIsOpen(false);
  };

  /* Hooks */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      const target: Node = ((event as MouseEvent).target ||
        (event as TouchEvent).targetTouches[0]) as Node;
      if (
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(target) &&
        !buttonRef.current.contains(target) &&
        isOpen
      ) {
        setIsOpen(false);
      }
    };

    const handleScroll = (event: Event) => {
      const target: Node = event.target as Node;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    document.addEventListener('scroll', handleScroll, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === KEY.ESCAPE && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  useEffect(() => {
    if (
      searchValue.length >= 3 &&
      prevSearchValue.substring(0, 3) !== searchValue.substring(0, 3)
    ) {
      const loadProducts = async () => {
        const data = await getProducts();
        if (data?.data) {
          setLayoutProducts(data.data);
          setPrevSearchValue(searchValue);
        }
      };
      loadProducts();
    }
  }, [getProducts, prevSearchValue, searchValue]);

  const filteredOptions = useMemo(() => {
    return layoutProducts.filter(
      (option) => option.productId.includes(searchValue) || option.layoutName?.includes(searchValue)
    );
  }, [layoutProducts, searchValue]);

  return (
    <View direction="column" gap={1}>
      <View.Item>
        <Text weight="bold" size="087">
          {t('ReplenishmentDashboard.AddReplenishment.Location.Label')}
        </Text>
      </View.Item>
      <Dropdown open={isOpen} contentGap={0} className={styles['dropdown-select-menu']}>
        <View attributes={{ ref: buttonRef }}>
          <Dropdown.Button
            label={t('ReplenishmentDashboard.AddReplenishment.Location.Placeholder')}
            onClick={toggleDropdown}
            className={styles['dropdown-select-menu__button']}
          >
            {selectedLocation && (
              <View align="start">
                <Text>{selectedLocation.label}</Text>
              </View>
            )}
          </Dropdown.Button>
        </View>
        <Dropdown.Content>
          <View
            attributes={{
              ref: dropdownRef,
            }}
            gap={1}
            padding={[2, 0]}
          >
            {searchable && (
              <View.Item>
                <View padding={[0, 2, 2, 2]}>
                  <SearchBar
                    className={styles['dropdown-select-menu__search-bar']}
                    suggestions={[]}
                    label="Search"
                    value={searchValue}
                    onValueChange={setSearchValue}
                  />
                </View>
                <Divider />
              </View.Item>
            )}

            <View direction="column" className={styles['dropdown-select-menu__scrollable-section']}>
              {isLoading ? (
                <DetailsTableSkeleton />
              ) : filteredOptions && filteredOptions.length === 0 ? (
                searchValue.length >= 3 && filteredOptions.length <= 0 ? (
                  <Text size="087" weight="medium" variant="text-link" align="center">
                    {t('PODashboard.NoMatch')}
                  </Text>
                ) : undefined
              ) : (
                filteredOptions &&
                filteredOptions.map((option, index: number) => (
                  <Actionable
                    className={styles['dropdown-select-menu__scrollable-section__option']}
                    onClick={() =>
                      onLocationSelect({
                        label: option.productId,
                        value: option.productId,
                        layoutName: option.layoutName || '',
                        layoutDistinctName: option.layoutDistinctName || '',
                        min: option.min,
                        max: option.max,
                      })
                    }
                  >
                    <View key={index} padding={[3, 4]}>
                      <Text
                        weight={selectedLocation?.value === option.productId ? 'bold' : 'medium'}
                        size="100"
                      >
                        {option.productId} - {t('ReplenishmentDashboard.AddReplenishment.SKU')}
                        {option.productId}
                      </Text>
                    </View>
                  </Actionable>
                ))
              )}
            </View>
          </View>
        </Dropdown.Content>
      </Dropdown>
    </View>
  );
};
