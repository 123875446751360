/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Divider, ProgressBar, Text, Icon, Link } from '@az/starc-ui';
import { WidgetWithChildren } from '@taskManagement/components/WidgetWithChildren/WidgetWithChildren';
import { t } from 'i18next';
import styles from '../CycleCountDetailsOverview.module.scss';

import { Stat } from '@shared/components/Stat/Stat';
import { ProgressCardFilters } from '@inventory/constants/constants';
import { CombinedPillTabs } from '@inventory/components/CombinedPillTabs/CombinedPillTabs';

import { ArrowRight } from '@az/starc-ui-icons';
import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { getValidNumber, getValidPercentage } from '@shared/utils/commonUtils';
import * as T from '../CycleCountDetailsOverview.types';

export const ProgressCard = ({ data }: T.Props) => {
  const { id } = useParams();

  const [slotsCountedPercentage, setSlotsCounted] = useState(0);
  const [reservedSlotsCountedPercentage, setReservedSlotsCounted] = useState(0);
  const [locationCountedPercentage, setLocationCountedPercentage] = useState(0);
  const [totalLocationCountExpected, setTotalLocationCountExpected] = useState(0);
  const [totalLocationCountCompleted, setTotalLocationCountCompleted] = useState(0);

  const [totalFpsLayoutCountExpected, setTotalFpsLayoutCountExpected] = useState(0);
  const [totalFpsLayoutCountCompleted, setTotalFpsLayoutCountCompleted] = useState(0);
  const [totalRsrvLayoutCountCompleted, setTotalRsrvLayoutCountCompleted] = useState(0);
  const [totalRsrvLayoutCountExpected, setTotalRsrvLayoutCountExpected] = useState(0);

  const [variancesCounted, setVariancesCounted] = useState(0);
  const [dollarVarianceOverThreshold, setDollarVarianceOverThreshold] = useState(0);
  const [pieceAdjustmentsOverThreshold, setPieceAdjustmentsOverThreshold] = useState(0);

  const [, setProgressValue] =
    useOutletContext<[unknown, React.Dispatch<React.SetStateAction<number>>]>();

  useEffect(() => {
    const taskData = data;

    if (taskData) {
      const {
        locationCounts: {
          totalFpsLayoutCountExpected,
          totalFpsLayoutCountCompleted,
          totalRsrvLayoutCountExpected,
          totalRsrvLayoutCountCompleted,
        },
        variancesCounted,
        dollarVarianceOverThreshold,
        pieceAdjustmentsOverThreshold,
      } = taskData;

      // Set total completed and expected counts
      const totalCompleted =
        (totalFpsLayoutCountCompleted ?? 0) + (totalRsrvLayoutCountCompleted ?? 0);
      const totalExpected =
        (totalFpsLayoutCountExpected ?? 0) + (totalRsrvLayoutCountExpected ?? 0);

      setTotalLocationCountCompleted(totalCompleted);
      setTotalLocationCountExpected(totalExpected);

      setTotalFpsLayoutCountExpected(totalFpsLayoutCountExpected ?? 0);
      setTotalFpsLayoutCountCompleted(totalFpsLayoutCountCompleted ?? 0);

      setTotalRsrvLayoutCountExpected(totalRsrvLayoutCountExpected ?? 0);
      setTotalRsrvLayoutCountCompleted(totalRsrvLayoutCountCompleted ?? 0);

      // Calculate percentages
      const fpsCountedPercentage =
        ((totalFpsLayoutCountCompleted ?? 0) / (totalFpsLayoutCountExpected ?? 1)) * 100;
      setSlotsCounted(fpsCountedPercentage);

      const rsrvCountedPercentage =
        ((totalRsrvLayoutCountCompleted ?? 0) / (totalRsrvLayoutCountExpected ?? 1)) * 100;
      setReservedSlotsCounted(rsrvCountedPercentage);

      setLocationCountedPercentage((totalCompleted / totalExpected) * 100);
      setProgressValue(getValidNumber(locationCountedPercentage.toFixed(2)));

      //set variances
      setVariancesCounted(variancesCounted?.varianceCount ?? 0);
      setDollarVarianceOverThreshold(dollarVarianceOverThreshold?.varianceCount ?? 0);
      setPieceAdjustmentsOverThreshold(pieceAdjustmentsOverThreshold?.varianceCount ?? 0);
    }
  }, [id, data]);
  return (
    <View direction="row">
      <View width="100%">
        <WidgetWithChildren className={styles['cycle-count-card']}>
          <View direction="row" align="center" justify="space-between" padding={2}>
            <Text weight="bold" size="125">
              {t(`CycleCount.ProgressCard.Title`)}
            </Text>
            <Link>
              <View direction="row" align="center" justify="space-between">
                <Text weight="bold" size="100">
                  {t(`CycleCount.ProgressCard.ViewSubzoneProgress`)}
                </Text>
                <Icon svg={ArrowRight} />
              </View>
            </Link>
          </View>
          <WidgetWithChildren.Body className={styles['shift-progress-body']}>
            <View direction="column">
              <View>
                <CombinedPillTabs TabsFilterData={ProgressCardFilters} />
              </View>
              <View direction="column">
                <View padding={[6, 0]}>
                  <Text weight="bold" color="500">
                    {t(`CycleCount.ProgressCard.LocationCounted`)}
                  </Text>
                </View>
                <View>
                  <View direction="row" justify="space-between" align="center">
                    <View>
                      <Text weight="bold" size="175">
                        {getValidPercentage(locationCountedPercentage)}
                      </Text>
                    </View>
                    <View>
                      <Text variant="small-body" color="500">
                        {totalLocationCountCompleted}/{totalLocationCountExpected}
                      </Text>
                    </View>
                  </View>
                  <View>
                    <ProgressBar
                      value={locationCountedPercentage}
                      max={100}
                      label=""
                      attributes={{ style: { width: '100%' } }}
                    />
                  </View>
                </View>
              </View>
              <View direction="row" padding={[4, 0]} gap={2} width="100%" align="center">
                <View.Item grow>
                  <Stat
                    title={t(`CycleCount.ProgressCard.SlotsCounted`)}
                    primaryText={getValidPercentage(slotsCountedPercentage)}
                    secondaryTextProps={{
                      secondaryText: `${totalFpsLayoutCountCompleted}/${totalFpsLayoutCountExpected}`,
                    }}
                    width="100%"
                  />
                </View.Item>
                <View.Item grow>
                  <Stat
                    title={t(`CycleCount.ProgressCard.RSRVsCounted`)}
                    primaryText={getValidPercentage(reservedSlotsCountedPercentage)}
                    secondaryTextProps={{
                      secondaryText: `${totalRsrvLayoutCountCompleted}/${totalRsrvLayoutCountExpected}`,
                    }}
                    width="100%"
                  />
                </View.Item>
              </View>
              <View>
                <Divider color="300" className={styles['shift-widget__divider']} />
              </View>
              <View padding={[4, 0]}>
                <View direction="row" align="center" justify="space-between">
                  <Text weight="bold" size="125">
                    {t(`CycleCount.ProgressCard.Variences`)}
                  </Text>
                  <Link>
                    <View direction="row" align="center" justify="space-between">
                      <Text weight="bold" size="100">
                        {t(`CycleCount.ProgressCard.ViewSKUswithVariances`)}
                      </Text>
                      <Icon svg={ArrowRight} />
                    </View>
                  </Link>
                </View>
                <View direction="row" padding={[4, 0]} gap={2} width="100%" align="center">
                  <View.Item grow>
                    <Stat
                      title={t(`CycleCount.ProgressCard.VariancesCounted`)}
                      primaryText={variancesCounted.toString()}
                      width="100%"
                    />
                  </View.Item>
                  <View.Item grow>
                    <Stat
                      title={t(`CycleCount.ProgressCard.DollarVariancesOver$250`)}
                      primaryText={dollarVarianceOverThreshold.toString()}
                      width="100%"
                    />
                  </View.Item>
                  <View.Item grow>
                    <Stat
                      title={t(`CycleCount.ProgressCard.PieceAdjustmentsOver250`)}
                      primaryText={pieceAdjustmentsOverThreshold.toString()}
                      width="100%"
                    />
                  </View.Item>
                </View>
              </View>
            </View>
          </WidgetWithChildren.Body>
        </WidgetWithChildren>
      </View>
    </View>
  );
};
