/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Button } from '@az/starc-ui';
import { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { TableStylingVariants } from '@shared/components/Table/tableConstants';
import {
  EMPTY_VALUE,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
  SORT_BY,
  SORT_ORDER,
} from '@shared/constants/constants';
import { Table } from '@shared/components/Table/Table';
import { DIRECTION, SortRowsParam } from '@shared/components/Table/Table.types';
import { Stat } from '@shared/components/Stat/Stat';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { useSubzoneLayoutSearch } from '@inventory/services/hooks/useLayoutSearch';
import { mapWallToWallSubzoneRows } from '@inventory/utils/table/subzoneLayoutTableUtils';
import { useCycleCount } from '@inventory/services/hooks/useCycleCounts';
import {
  CHOOSE_ASSIGNEE_CONTENT_TYPE,
  CYCLE_COUNT_ENTITY_ASSOCIATION,
  CYCLE_COUNT_STATUS_CD,
  CYCLE_COUNT_TYPE_CD,
  DEFAULT,
  SUBZONE_ENTITY_CD,
  SINGLE_PAGE_SIZE,
  SUBZONE_ATTRIBUTE_KEY,
  USER_STATUS_CD,
} from '@inventory/constants/constants';
import { ENTITY_ASSOCIATIONS } from '@mdm/constants/constants';
import { DEFAULT_PAGE } from '@inbound/constants/constants';
import { WALL_TO_WALL_SUBZONES_TABLE_COLUMNS } from '@inventory/constants/tableColumns/wallToWallSubzone';
import { SubzoneType } from '@inventory/types/types';
import { useMutateSubZoneAssignees } from '@inventory/services/hooks/useMutateSubZoneAssignees';
import {
  StartCountParams,
  SubZoneAssigneesLocCountSyncParams,
} from '@inventory/types/service.types';
import {
  assigneeListType,
  attributeType,
  countLayoutSubZoneType,
  startCountPayloadDataType,
  WallToWallSubzoneRowType,
} from './Subzones.types';
import styles from './Subzones.module.scss';
import { useEndCount } from '@inventory/services/hooks/useEndCount';
import { useMutateStartCount } from '@inventory/services/hooks/useMutateStartCount';

const LazyLoadChooseAssigneesModal = lazy(() =>
  import('@inventory/components/CycleCountsModal/ChooseAssigneesModal/ChooseAssigneesModal').then(
    (module) => ({ default: module.ChooseAssigneesModal })
  )
);

export const Subzones = () => {
  const [, setCurrentPage] = useState<number>(DEFAULT_PAGE);
  const [, setSortBy] = useState<string>('');
  const [direction, setDirection] = useState<string>(DIRECTION.ASCENDING);
  const [tableData, setTableData] = useState<SortRowsParam[]>([]);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { handleNotification } = useNotificationHandler();
  const [showAssigneeModal, setShowAssigneeModal] = useState<boolean>(false);
  const [payloadCountLayoutSubZone, setPayloadCountLayoutSubZone] =
    useState<countLayoutSubZoneType>();
  const [, setHandleIndex] = useState<number>();
  const [handleLayoutDistinctName, setHandleLayoutDistinctName] = useState<string>();
  const [handleParentLayoutDistinctName, setHandleParentLayoutDistinctName] = useState<string>();
  // callback function to handle the selected assignees and location count data
  const [assigneeListPayloadData, setAssigneeListPayloadData] = useState<assigneeListType[]>([
    {
      userId: '',
      firstName: '',
      lastName: '',
      statusCd: '',
      layoutLocationCount: null,
      assignee: '',
    },
  ]);
  const [locationCountFlag, setLocationCountFlag] = useState<boolean>(true);
  const [slotRsrvTotalCount, setSlotRsrvTotalCount] = useState<number>();

  /* Get the phase data from local storage and set the statusCd depends on that call the layout or location count API */
  const cycleCountPhaseData = JSON.parse(localStorage.getItem('cycleCountPhaseData') ?? '');
  const statusCd = cycleCountPhaseData.statusCd;
  const [isBtnsHidden, setIsBtnsHidden] = useState<boolean>(false);
  const [isAnyUserAssigned, setIsAnyUserAssigned] = useState<boolean>(false);

  /* Query for Layout Search data */
  const { subzoneLayoutSearchData } = useSubzoneLayoutSearch(
    {
      searchPage: {
        page: DEFAULT_PAGE,
        size: PAGE_SIZE,
        sort: [
          {
            sortBy: SORT_BY.LAYOUT_NAME,
            direction: direction,
          },
        ],
      },
      searchCriteria: {
        entityCd: SUBZONE_ENTITY_CD,
        entityTypeCd: DEFAULT,
        entityAssociations: [ENTITY_ASSOCIATIONS.LAYOUT_ATTRIBUTES],
      },
    },
    statusCd === CYCLE_COUNT_STATUS_CD.NEW
  );

  /* Query for Location-count Search data */
  const { wallToWallData: SubzonesCycleCountData } = useCycleCount(
    {
      searchPage: {
        page: DEFAULT_PAGE,
        size: SINGLE_PAGE_SIZE,
        sort: [
          {
            sortBy: SORT_BY.END_TS,
            direction: SORT_ORDER.DESC,
          },
        ],
      },
      searchCriteria: {
        countRequest: {
          countTypeCd: CYCLE_COUNT_TYPE_CD.WALL_TO_WALL,
          statusCd: [statusCd],
          taskId: id,
        },
        entitiyAssociations: [
          CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_SUBZONE,
          CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_ASSIGNED_USER,
          CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_LOCATION,
        ],
      },
    },
    statusCd === CYCLE_COUNT_STATUS_CD.INPROGRESS || statusCd === CYCLE_COUNT_STATUS_CD.COMPLETE
  );

  /* Query for perform-action-sync data */
  const { mutateEndCount } = useEndCount();
  const { mutateStartCount } = useMutateStartCount();

  /* Query for assign Users and Counts with location count command handler perform-action-sync API */
  const { isLoading: isMutateSubzoneAssignee, mutateSubZoneAssignees } =
    useMutateSubZoneAssignees();

  /* Functions */
  const onSubmit = (status: string) => {
    const currentTimestamp = new Date().toISOString();

    mutateEndCount({
      countRequest: {
        taskId: String(id),
        countTypeCd: CYCLE_COUNT_TYPE_CD.WALL_TO_WALL,
        statusCd: status,
        endTs: currentTimestamp,
      },
    });
    if (status === CYCLE_COUNT_STATUS_CD.INPROGRESS) {
      setIsBtnsHidden(true);
    }
  };

  /* On Click of Start Count bottom button execute below function */
  const handleStartCount = useCallback(
    (status: string) => {
      const newCountLayoutSubZoneData: startCountPayloadDataType[] = [];

      SubzonesCycleCountData?.content?.forEach((data) =>
        data.subzones?.forEach((subZone) => {
          const {
            parentLayoutDistinctName,
            parentLayoutName,
            parentMapSequence,
            totalFpsLayoutCountExpected,
            totalRsrvLayoutCountExpected,
          } = subZone;
          newCountLayoutSubZoneData.push({
            countRequest: {
              taskId: String(id),
              countTypeCd: CYCLE_COUNT_TYPE_CD.WALL_TO_WALL,
              statusCd: status,
              startTs: new Date().toISOString(),
            },
            countLayoutSubzone: {
              parentLayoutDistinctName: parentLayoutDistinctName || '',
              parentLayoutName: parentLayoutName || '',
              parentMapSequence: parentMapSequence || null,
              startTs: new Date().toISOString(),
              fpsChildCount: totalFpsLayoutCountExpected || null,
              rsrvChildCount: totalRsrvLayoutCountExpected || null,
            },
          });
        })
      );

      if (newCountLayoutSubZoneData.length > 0) {
        const payload: { UpdateLocationCountRequest: StartCountParams } = {
          UpdateLocationCountRequest: newCountLayoutSubZoneData as StartCountParams,
        };
        mutateStartCount(payload, {
          onSuccess: (data) => {
            if (data) {
              queryClient.invalidateQueries(['subzoneList']);
              const successMessage = t('CycleCount.Notification.UpdateStartCount.Success');
              handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
            }
          },
          onError: (error) => {
            const errorMessage = t('CycleCount.Notification.UpdateStartCount.Error');
            error ?? handleNotification(NOTIFICATION_TYPES.ERROR, errorMessage);
          },
        });
      }
    },
    [SubzonesCycleCountData?.content, handleNotification, id, mutateStartCount, queryClient, t]
  );

  /* Onclick of specific row of subZone handle below function for Assign users and counts */
  const handleMultipleAssignee = (
    index: number,
    layoutDistinctName: string,
    parentLayoutDistinctName: string
  ) => {
    setShowAssigneeModal(true);
    setHandleIndex(index);
    setHandleLayoutDistinctName(layoutDistinctName);
    setHandleParentLayoutDistinctName(parentLayoutDistinctName);
  };

  /* Hook */
  useEffect(() => {
    let hasAssignedUsers = false;

    /* Fetch data based on the statusCd */
    if (statusCd === CYCLE_COUNT_STATUS_CD.NEW) {
      const mappedData: WallToWallSubzoneRowType[] = [];
      subzoneLayoutSearchData?.content.map((item, index) => {
        mappedData.push({
          index: index,
          subzone: item.layout.layoutName || '',
          timeStarted: EMPTY_VALUE,
          counter: [],
          reCounter: EMPTY_VALUE,
          netAdjusted: EMPTY_VALUE,
          absolutePCAccuracy: EMPTY_VALUE,
          pcAdjustments: EMPTY_VALUE,
          variances: EMPTY_VALUE,
          locationProgress: null,
          totalActions: EMPTY_VALUE,
          locationsAssigned: EMPTY_VALUE,
          locationsCompleted: EMPTY_VALUE,
          layoutDistinctName: item.layout.layoutDistinctName || EMPTY_VALUE,
        });
      });
      setTableData(mapWallToWallSubzoneRows(mappedData, handleMultipleAssignee));
    } else {
      const locationCountData: WallToWallSubzoneRowType[] = [];
      SubzonesCycleCountData?.content?.map((item, index) => {
        if (item && item.subzones) {
          item.subzones.map((subzone) => {
            const assignedUsers = subzone.assignedUsers || [];
            if (assignedUsers.length > 0) {
              hasAssignedUsers = true;
            }
            locationCountData.push({
              index: index,
              subzone: subzone?.parentLayoutName || EMPTY_VALUE,
              timeStarted: subzone.startTs || EMPTY_VALUE,
              counter:
                subzone.assignedUsers?.map(
                  (assignee) => `${assignee.firstName} ${assignee.lastName}`
                ) || [],
              reCounter: EMPTY_VALUE,
              netAdjusted:
                subzone.totalInvAmtExpected && subzone.totalInvAmtCounted
                  ? subzone.totalInvAmtCounted - subzone.totalInvAmtExpected
                  : EMPTY_VALUE,
              absolutePCAccuracy:
                subzone.totalInvAmtCounted && subzone.totalInvAmtExpected
                  ? (subzone.totalInvAmtCounted * 100) / subzone.totalInvAmtExpected
                  : EMPTY_VALUE,
              pcAdjustments: EMPTY_VALUE,
              variances: subzone.totalInvAmtExceedsVariance || EMPTY_VALUE,
              locationProgress: getLocationProgressValues(subzone),
              totalActions:
                subzone.totalFpsLayoutCountExpected && subzone.totalRsrvLayoutCountExpected
                  ? subzone.totalFpsLayoutCountExpected + subzone.totalRsrvLayoutCountExpected
                  : EMPTY_VALUE,
              locationsAssigned: subzone.layoutLocationsAssigned || EMPTY_VALUE,
              locationsCompleted:
                subzone.totalFpsLayoutCountCompleted && subzone.totalRsrvLayoutCountCompleted
                  ? subzone.totalFpsLayoutCountCompleted + subzone.totalRsrvLayoutCountCompleted
                  : EMPTY_VALUE,
              totalFpsLayoutCountExpected: subzone.totalFpsLayoutCountExpected
                ? subzone.totalFpsLayoutCountExpected
                : null,
              totalRsrvLayoutCountExpected: subzone.totalRsrvLayoutCountExpected
                ? subzone.totalRsrvLayoutCountExpected
                : null,
              parentLayoutDistinctName: subzone.parentLayoutDistinctName || EMPTY_VALUE,
            });
          });
        }
      });
      setTableData(mapWallToWallSubzoneRows(locationCountData, handleMultipleAssignee));
    }
    setIsAnyUserAssigned(hasAssignedUsers);
  }, [statusCd, subzoneLayoutSearchData, SubzonesCycleCountData]);

  const getLocationProgressValues = (subzone: SubzoneType) => {
    let minValue;
    let maxValue;

    if (subzone.totalFpsLayoutCountCompleted && subzone.totalRsrvLayoutCountCompleted) {
      minValue = subzone.totalFpsLayoutCountCompleted + subzone.totalRsrvLayoutCountCompleted;
    }

    if (subzone.totalFpsLayoutCountExpected && subzone.totalRsrvLayoutCountExpected) {
      maxValue = subzone.totalFpsLayoutCountExpected + subzone.totalRsrvLayoutCountExpected;
    }
    return { min: minValue, max: maxValue };
  };

  /* After assigned subZone's assignees and location count clicked on save button then execute this function */
  const OnClickSaveSubZoneAssignee = () => {
    const assignedUserPayload: assigneeListType[] = [...assigneeListPayloadData];

    const subZoneAssigneesData = assignedUserPayload.map(
      (user) =>
        user.userId &&
        user.layoutLocationCount && {
          countRequest: {
            countTypeCd: CYCLE_COUNT_TYPE_CD.WALL_TO_WALL,
            statusCd: CYCLE_COUNT_STATUS_CD.INPROGRESS,
            taskId: id,
          },

          countLayoutSubzone: payloadCountLayoutSubZone,
          user: {
            userId: user.userId ? user.userId : '',
            firstName: user.firstName ? user.firstName : '',
            lastName: user.lastName ? user.lastName : '',
            statusCd: USER_STATUS_CD.ASSIGNED,
            layoutLocationCount: user.layoutLocationCount ? user.layoutLocationCount : null,
          },
        }
    );

    const hasNullIndex = subZoneAssigneesData.some(
      (user) => user === null || user === undefined || user.toString() === ''
    );

    if (hasNullIndex) {
      const errorMessage = t('CycleCount.Notification.AssigneeMsg');
      handleNotification(NOTIFICATION_TYPES.ERROR, errorMessage);
      return;
    }

    const payload: { AssignSubZoneLocCountRequest: SubZoneAssigneesLocCountSyncParams } = {
      AssignSubZoneLocCountRequest: subZoneAssigneesData as SubZoneAssigneesLocCountSyncParams,
    };
    {
      subZoneAssigneesData &&
        mutateSubZoneAssignees(payload, {
          onSuccess: (data) => {
            if (data && !isMutateSubzoneAssignee) {
              queryClient.invalidateQueries(['subzoneList']);
              const successMessage = t('CycleCount.Notification.UpdateSubzoneAssignees.Success');
              handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
            }
            setShowAssigneeModal(false);
          },
          onError: (error) => {
            const errorMessage = t('Errors.Action.UpdateSubZoneAssignee');
            error ?? handleNotification(NOTIFICATION_TYPES.ERROR, errorMessage);
          },
        });
    }
  };

  /* Hook  - Check SLOT & RSRV & FPS Count with assigned location count*/
  useEffect(() => {
    if (slotRsrvTotalCount !== undefined) {
      const sumOfLocationCount = assigneeListPayloadData.reduce((total, assignee) => {
        return total + (assignee.layoutLocationCount ? assignee.layoutLocationCount : 0);
      }, 0);

      if (sumOfLocationCount === 0 || sumOfLocationCount < 0) {
        setLocationCountFlag(true);
      } else if (sumOfLocationCount > slotRsrvTotalCount) {
        setLocationCountFlag(true);
      } else {
        setLocationCountFlag(false);
      }
    }
  }, [assigneeListPayloadData, slotRsrvTotalCount]);

  /*  Hook - Filter Location count API with assign Users and counts Build Payload */
  useEffect(() => {
    const locationCountAPIData = SubzonesCycleCountData?.content
      ?.flatMap((data) => data.subzones)
      ?.find((subzone) => subzone?.parentLayoutDistinctName === handleParentLayoutDistinctName);

    if (locationCountAPIData) {
      const {
        parentLayoutDistinctName,
        parentLayoutName,
        parentMapSequence,
        totalFpsLayoutCountExpected,
        totalRsrvLayoutCountExpected,
      } = locationCountAPIData;
      const newCountLayoutSubZoneData: countLayoutSubZoneType = {
        parentLayoutDistinctName: parentLayoutDistinctName || '',
        parentLayoutName: parentLayoutName || '',
        parentMapSequence: parentMapSequence || null,
        startTs: new Date().toISOString(),
        fpsChildCount: totalFpsLayoutCountExpected || null,
        rsrvChildCount: totalRsrvLayoutCountExpected || null,
      };
      setPayloadCountLayoutSubZone(newCountLayoutSubZoneData);

      setSlotRsrvTotalCount(
        Number(locationCountAPIData.totalFpsLayoutCountExpected) +
          Number(locationCountAPIData.totalRsrvLayoutCountExpected)
      );
    }
  }, [SubzonesCycleCountData?.content, handleParentLayoutDistinctName]);

  /* Filter the layout data by layoutKey and calculate the sum of SLOT_COUNT and RSRV_COUNT cycleCountPhaseData*/
  useEffect(() => {
    const filteredLayoutData = subzoneLayoutSearchData?.content
      ?.flatMap((data) => data.layout)
      ?.find((layout) => layout?.layoutDistinctName === handleLayoutDistinctName);

    if (filteredLayoutData) {
      const slotCount =
        filteredLayoutData?.attributes.find(
          (attr: attributeType) => attr.name === SUBZONE_ATTRIBUTE_KEY.SLOT_COUNT
        )?.value || null;
      const rsrvCount =
        filteredLayoutData?.attributes.find(
          (attr: attributeType) => attr.name === SUBZONE_ATTRIBUTE_KEY.RSRV_COUNT
        )?.value || null;
      setSlotRsrvTotalCount(Number(slotCount) + Number(rsrvCount));
      /* when Create NEW Phase with SubZone layout API having statusCd as NEW */
      if (statusCd === CYCLE_COUNT_STATUS_CD.NEW) {
        const { layoutDistinctName, layoutName, mapSequence } = filteredLayoutData;
        const newCountLayoutSubZoneData: countLayoutSubZoneType = {
          parentLayoutDistinctName: layoutDistinctName || '',
          parentLayoutName: layoutName || '',
          parentMapSequence: mapSequence || null,
          startTs: new Date().toISOString(),
          fpsChildCount: Number(slotCount),
          rsrvChildCount: Number(rsrvCount),
        };
        setPayloadCountLayoutSubZone(newCountLayoutSubZoneData);
      }
    }
  }, [subzoneLayoutSearchData?.content, handleLayoutDistinctName, statusCd]);

  return (
    <>
      {showAssigneeModal && (
        <Suspense>
          <LazyLoadChooseAssigneesModal
            showModal={showAssigneeModal}
            contentType={CHOOSE_ASSIGNEE_CONTENT_TYPE.MULTIPLE_ASSIGNEE}
            onClose={() => setShowAssigneeModal(false)}
            OnClickSaveSubZoneAssignee={OnClickSaveSubZoneAssignee}
            setAssigneeListPayloadData={setAssigneeListPayloadData}
            locationCountFlag={locationCountFlag}
          />
        </Suspense>
      )}
      <View className={styles['subzone-view-screen']}>
        <View
          direction="row"
          justify="end"
          align="end"
          padding={[4, 6]}
          className={styles['subzone-view-screen__statistics-section__statistics']}
        >
          <Stat
            title={t('CycleCount.WallToWallPhases.Subzone.Stats.LocationsCounted')}
            primaryText="31.79%"
            secondaryTextProps={{
              secondaryText: '700 / 2,025',
            }}
            width="220px"
            size="medium"
          />
          <Stat
            title={t('CycleCount.WallToWallPhases.Subzone.Stats.SlotsCounted')}
            primaryText="39.88%"
            secondaryTextProps={{
              secondaryText: '650 / 1,630',
            }}
            width="220px"
            size="medium"
          />
          <Stat
            title={t('CycleCount.WallToWallPhases.Subzone.Stats.RSRVCounted')}
            primaryText="69.54%"
            secondaryTextProps={{
              secondaryText: '280 / 455',
            }}
            width="220px"
            size="medium"
          />
        </View>

        <View gap={2} className={styles['subzone-view-screen__table-view']}>
          <Table
            columns={WALL_TO_WALL_SUBZONES_TABLE_COLUMNS}
            rows={tableData}
            isPaginated={false}
            isCheckboxDisabled={false}
            isApiLoadedData={true}
            pageSize={PAGE_SIZE}
            defaultPage={DEFAULT_PAGE}
            isCreditItem={false}
            isCheckboxTable={false}
            totalPages={Math.ceil(tableData?.length / PAGE_SIZE)}
            styleVariant={TableStylingVariants.DETAILS}
            onSort={(_sorting, _columnId) => {
              _sorting.forEach((sorting) => {
                if (sorting.id === _columnId) {
                  setSortBy(_columnId);
                  setDirection(sorting.direction);
                }
              });
            }}
            onPageChange={(_pageNumber) => {
              setCurrentPage(_pageNumber);
            }}
          />
        </View>
      </View>
      {!isBtnsHidden && (
        <View gap={3} direction="row" padding={[2, 6, 4, 6]} justify="end">
          <View.Item>
            <Button
              variant="secondary"
              size="large"
              onClick={() => onSubmit(CYCLE_COUNT_STATUS_CD.COMPLETE)}
            >
              {t(`CycleCount.ChooseAssigneesModal.Cancel`)}
            </Button>
          </View.Item>
          <View.Item gapBefore={6}>
            <Button
              size="large"
              onClick={() => handleStartCount(CYCLE_COUNT_STATUS_CD.INPROGRESS)}
              disabled={!isAnyUserAssigned}
            >
              {t(`CycleCount.ChooseAssigneesModal.StartCount`)}
            </Button>
          </View.Item>
        </View>
      )}
    </>
  );
};
