/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { InboundOrderDataType } from '@inventory/types/types';

export const getSubzone = (input: string) => {
  const match = input.match(/SZ=([^,]*)/);
  const szValue = match ? match[1] : null;
  return szValue;
};

export const getInboundOrderDataBySubzone = (
  inboundOrderData: InboundOrderDataType[],
  subzone: string
) =>
  inboundOrderData?.filter((item) =>
    item.inboundOrder.inboundOrderLines.some(
      (line) =>
        line.inboundOrderLineLocations &&
        line.inboundOrderLineLocations.some((location) => {
          if (getSubzone(location.layoutDistinctName) === subzone) {
            return true;
          } else {
            return false;
          }
        })
    )
  );
