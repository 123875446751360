/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Tabs, View } from '@az/starc-ui';

import { CycleCountDetailsTabsData } from './CycleCountDetailsTabs.constants';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CycleCountDetailsTabs.module.scss';
import { PAGE_URLS } from '@shared/constants/routes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const CycleCountDetailsTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const rootPath = PAGE_URLS.CYCLE_COUNTS_DETAILS(id ?? '');
  const hasTabAfterRoot = new RegExp(`${rootPath}/.+`).test(location.pathname);

  const fetchDefaultTab = useCallback((): string => {
    if (!hasTabAfterRoot) {
      return CycleCountDetailsTabsData[0].label;
    }

    const currentTab = location.pathname.split('/').at(-1);
    return CycleCountDetailsTabsData.find((data) => data.value === currentTab)?.label ?? '';
  }, [hasTabAfterRoot, location.pathname]);

  const defaultTab = fetchDefaultTab();

  useEffect(() => {
    if (!hasTabAfterRoot) {
      navigate(`${location.pathname}/${CycleCountDetailsTabsData[0].value}`, { replace: true });
    }
  }, [hasTabAfterRoot, defaultTab, location, navigate]);

  const onTabsChange = useCallback(
    (value: string) => {
      navigate(`${rootPath}/${value}`);
    },
    [navigate, rootPath]
  );

  return (
    <View backgroundColor="secondary">
      <Tabs
        zeroBorder
        onTabChange={onTabsChange}
        defaultTab={t(defaultTab)}
        className={styles['cycle-counts-tabs']}
        key={defaultTab}
      >
        {CycleCountDetailsTabsData.map((tab) => {
          return (
            <Tabs.Item value={tab.value} key={tab.value} label={t(tab.label)}>
              <></>
            </Tabs.Item>
          );
        })}
      </Tabs>
    </View>
  );
};
