/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Button, Text } from '@az/starc-ui';
import { Tag } from '@shared/components/Tag/Tag';
import { PriorityTag } from '@inbound/components/PriorityTag/PriorityTag';
import { TrailerOrderListType } from '@inbound/types/types';
import {
  FinalizationDashboardDataRowType,
  FinalizationDashboardDividerRowType,
  FinalizationDashboardLocationRowType,
  TopVarianceProductCategoryType,
} from '@inventory/types/types';
import { generateDateString } from '@ofm/utils/utils';
import { EMPTY_VALUE } from '@shared/constants/constants';
import { t } from 'i18next';
import { INBOUND_ORDER_STATUS } from '@inbound/constants/constants';

export const mapTrailerOrders = (poOrders: TrailerOrderListType[]) => {
  return poOrders.map(({ trailerOrder }) => ({
    receiptId: trailerOrder.receiptId,
    priority: trailerOrder.inboundOrder.priority,
    arrivalTime: new Date(trailerOrder.trailerArrival.trailerArrivalTs),
    vendorName: trailerOrder.inboundOrder.vendorLocale
      ? trailerOrder.inboundOrder.vendorLocale.vendorName || ''
      : '',
    poNumber: trailerOrder.sourceOrderNbr,
    location:
      trailerOrder.trailerArrival.trailerLocationTypeCd &&
      trailerOrder.trailerArrival.trailerLocationId
        ? trailerOrder.trailerArrival.trailerLocationTypeCd +
          ' ' +
          trailerOrder.trailerArrival.trailerLocationId
        : '',
    commodity: trailerOrder.commodityTypeCd,
    poType: trailerOrder.inboundOrder.orderTypeCd || '',
    shippedPcs: trailerOrder.inboundOrder.totalOrderedQty,
    linesWithError: trailerOrder.inboundOrder.inboundOrderLines?.filter(
      (line) => line.statusCd == INBOUND_ORDER_STATUS.IC_REVIEW_IN_PROGRESS
    ).length,
    sentFromInbound:
      trailerOrder.inboundOrder.inventoryControlTs &&
      new Date(trailerOrder.inboundOrder.inventoryControlTs),
    inboundOrderLines: trailerOrder.inboundOrder.inboundOrderLines,
  }));
};
const generateFinalizeDashboardTableCells = (
  order: FinalizationDashboardDividerRowType | FinalizationDashboardDataRowType,
  onBeginChecking: (poNumber: string) => void
) => {
  return [
    {
      value: order.priority && <PriorityTag />,
      sortValue: order.priority,
    },
    {
      value: order.vendorName || EMPTY_VALUE,
      sortValue: order.vendorName,
    },
    { value: order.poNumber },
    {
      value: order.arrivalTime && generateDateString(order.arrivalTime, t('DateFormat.ShortTime')),
      sortValue:
        order.arrivalTime && generateDateString(order.arrivalTime, t('DateFormat.ShortTime')),
    },

    {
      value: order.location || EMPTY_VALUE,
    },
    {
      value: order.shippedPcs || EMPTY_VALUE,
      sortValue: order.shippedPcs,
    },
    {
      value: order.linesWithError || EMPTY_VALUE,
      sortValue: order.linesWithError,
    },
    {
      value: order.poType && <Tag variant="order" text={order.poType} />,
    },
    {
      value: order.commodity && <Tag variant="order" text={order.commodity} />,
    },
    {
      value:
        (order.sentFromInbound &&
          generateDateString(order.sentFromInbound, t('DateFormat.ShortTime'))) ||
        EMPTY_VALUE,
      sortValue:
        order.sentFromInbound &&
        generateDateString(order.sentFromInbound, t('DateFormat.ShortTime')),
    },
    {
      value: (
        <View>
          <Button variant="secondary" onClick={() => onBeginChecking(order.poNumber)}>
            {t('FinalizationDashboard.BeginChecking')}
          </Button>
        </View>
      ),
    },
  ];
};
export const mapFinalizationDashboardRows = (
  poOrders: FinalizationDashboardDividerRowType[],
  onBeginChecking: (poNumber: string) => void
) => {
  return poOrders.map((order) => ({
    id: `${order.poNumber.toString()}-${order.receiptId}`,
    dividerLabel: order.dividerLabel ? order.dividerLabel : '',
    cells: order.dividerLabel ? [] : generateFinalizeDashboardTableCells(order, onBeginChecking),
  }));
};

export const mapFinalizationDashboardLocationsRows = (
  orders: FinalizationDashboardLocationRowType[],
  onBeginChecking: (subzone: string) => void
) => {
  return orders.map((order) => ({
    id: `${order.productId?.toString() || ''}`,
    cells: [
      {
        value: order.subzoneName || EMPTY_VALUE,
        sortValue: order.subzoneName,
      },
      {
        value: order.location || EMPTY_VALUE,
      },
      {
        value: order.poWithError || EMPTY_VALUE,
        sortValue: order.poWithError,
      },
      {
        value:
          order.linesWithError >= 10 ? (
            <Text color="error">{order.linesWithError}</Text>
          ) : (
            order.linesWithError || EMPTY_VALUE
          ),
        sortValue: order.linesWithError,
      },

      {
        value: (
          <View>
            <Button variant="secondary" onClick={() => onBeginChecking(order.subzoneName)}>
              {t('FinalizationDashboard.BeginChecking')}
            </Button>
          </View>
        ),
      },
    ],
  }));
};

export const mapProductCategoryTableCells = (sampleData: TopVarianceProductCategoryType[]) => {
  const rows = sampleData.map(
    (
      product: { productCategory: string; dollarVariances: string },
      index: { toString: () => string }
    ) => ({
      id: index.toString(),
      cells: [
        {
          value: (
            <View direction="row" gap={4} width="max-content">
              <Text size="087" weight="bold" color="gray-500">
                {+index + 1}
              </Text>
              <Text size="087" weight="medium" color="primary">
                {product.productCategory}
              </Text>
            </View>
          ),
        },
        {
          value: product.dollarVariances || EMPTY_VALUE,
          sortvalue: product.dollarVariances,
        },
      ],
    })
  );
  return rows;
};
