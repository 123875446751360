/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const AttributesSchema = z.object({
  name: z.string(),
  value: z.string(),
});

export const LayoutSchema = z.object({
  layoutKey: z.string(),
  layoutName: z.string(),
  layoutNameDesc: z.string(),
  layoutDistinctName: z.string(),
  entityCd: z.string(),
  entityTypeCd: z.string(),
  active: z.boolean(),
  mapSequence: z.number(),
  attributes: z.array(AttributesSchema),
});

const ProductLayoutSchema = z.object({
  productId: z.string(),
  layoutName: z.string().nullable(),
  layoutDistinctName: z.string(),
  min: z.number(),
  max: z.number(),
  layout: LayoutSchema.nullable(),
});

export const LayoutProductListSchema = z.array(ProductLayoutSchema);
