/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text, Icon, Link } from '@az/starc-ui';
import { WidgetWithChildren } from '@taskManagement/components/WidgetWithChildren/WidgetWithChildren';
import { t } from 'i18next';
import styles from '../CycleCountDetailsOverview.module.scss';
import { Stat } from '@shared/components/Stat/Stat';
import { AccuracyMeasurementCardFilters } from '@inventory/constants/constants';
import { CombinedPillTabs } from '@inventory/components/CombinedPillTabs/CombinedPillTabs';
import * as T from '../CycleCountDetailsOverview.types';

import { ArrowRight } from '@az/starc-ui-icons';
import { useEffect, useState } from 'react';
import { getValidNumber, getValidPercentage } from '@shared/utils/commonUtils';
import { useParams } from 'react-router-dom';

export const AccuracyMeasurementCard = ({ data }: T.Props) => {
  const { id } = useParams();

  const [netDollarAccuracy, setNetDollarAccuracy] = useState(0);
  const [absoluteDollarAccuracy, setAbsoluteDollarAccuracy] = useState(0);
  const [totalDollarsCounted, setTotalDollarsCounted] = useState(0);
  const [netDollarVariance, setNetDollarVariance] = useState(0);
  const [absoluteDollarVariance, setAbsoluteDollarVariance] = useState(0);

  const [netPieceAccuracy, setNetPieceAccuracy] = useState(0);
  const [absolutePieceAccuracy, setAbsolutePieceAccuracy] = useState(0);
  const [totalPiecesCounted, setTotalPiecesCounted] = useState(0);
  const [netPieceVariance, setNetPieceVariance] = useState(0);
  const [absolutePieceVariance, setAbsolutePieceVariance] = useState(0);

  useEffect(() => {
    const taskData = data;

    if (taskData && taskData.accuracyMeasurements) {
      const {
        accuracyMeasurements: {
          totalInvAmtCounted,
          totalInvAmtExpected,
          totalInvQtyCounted,
          totalInvQtyExpected,
          totalInvAmtAdjCalc,
          totalInvQtyAdjustedCalc,
        },
      } = taskData;

      if (
        totalInvAmtExpected !== null &&
        totalInvAmtCounted !== null &&
        totalInvAmtAdjCalc !== null
      ) {
        const netDollarVariance = totalInvAmtExpected - totalInvAmtCounted;
        const absoluteDollarVariance = (totalInvAmtCounted * 100) / totalInvAmtExpected;
        setNetDollarAccuracy(absoluteDollarVariance);
        setAbsoluteDollarAccuracy((totalInvAmtCounted - totalInvAmtAdjCalc) / totalInvAmtCounted);

        setTotalDollarsCounted(totalInvAmtCounted);
        setNetDollarVariance(netDollarVariance);
        setAbsoluteDollarVariance(absoluteDollarVariance);
      }

      if (
        totalInvQtyExpected !== null &&
        totalInvQtyCounted !== null &&
        totalInvQtyAdjustedCalc !== null
      ) {
        const netPiecesVariance = totalInvQtyExpected - totalInvQtyCounted;
        const absolutePieceVariance = (totalInvQtyCounted * 100) / totalInvQtyExpected;

        setNetPieceAccuracy(absolutePieceVariance);
        setAbsolutePieceAccuracy(
          (totalInvQtyCounted - totalInvQtyAdjustedCalc) / totalInvQtyCounted
        );
        setTotalPiecesCounted(totalInvQtyCounted);
        setNetPieceVariance(netPiecesVariance);
        setAbsolutePieceVariance(absolutePieceVariance);
      }
    }
  }, [id, data]);
  return (
    <View direction="row">
      <View width="100%">
        <WidgetWithChildren className={styles['cycle-count-card']}>
          <View direction="row" align="center" justify="space-between" padding={2}>
            <Text weight="bold" size="125">
              {t(`CycleCount.AccuracyMeasurementsCard.Title`)}
            </Text>
            <Link>
              <View direction="row" align="center" justify="space-between">
                <Text weight="bold" size="100">
                  {t(`CycleCount.AccuracyMeasurementsCard.ViewCustomDateRange`)}
                </Text>
                <Icon svg={ArrowRight} />
              </View>
            </Link>
          </View>
          <WidgetWithChildren.Body className={styles['shift-progress-body']}>
            <View direction="column">
              <View>
                <CombinedPillTabs TabsFilterData={AccuracyMeasurementCardFilters} />
              </View>
              <View direction="column" padding={[6, 0]}>
                <View direction="column">
                  <View>
                    <Text weight="bold" color="500">
                      {t(`CycleCount.AccuracyMeasurementsCard.DOLLERS`)}
                    </Text>
                  </View>
                </View>
                <View direction="row" padding={[4, 0]} gap={2} width="100%" align="center">
                  <View.Item grow>
                    <Stat
                      title={t(`CycleCount.AccuracyMeasurementsCard.NetDollarAccuracy`)}
                      primaryText={getValidPercentage(netDollarAccuracy)}
                      width="100%"
                    />
                  </View.Item>
                  <View.Item grow>
                    <Stat
                      title={t(`CycleCount.AccuracyMeasurementsCard.AbsoluteDollarAccuracy`)}
                      primaryText={getValidPercentage(absoluteDollarAccuracy)}
                      width="100%"
                    />
                  </View.Item>
                </View>
                <View direction="row" gap={2} width="100%" align="center">
                  <View.Item grow className={styles['gray-card']}>
                    <Stat
                      title={t(`CycleCount.AccuracyMeasurementsCard.TotalDollarsCounted`)}
                      primaryText={'$' + getValidNumber(totalDollarsCounted.toFixed(2))}
                      width="100%"
                    />
                  </View.Item>
                  <View.Item grow className={styles['gray-card']}>
                    <Stat
                      title={t(`CycleCount.AccuracyMeasurementsCard.NetDollarVariance`)}
                      primaryText={'$' + getValidNumber(netDollarVariance.toFixed(2))}
                      width="100%"
                    />
                  </View.Item>
                  <View.Item grow className={styles['gray-card']}>
                    <Stat
                      title={t(`CycleCount.AccuracyMeasurementsCard.AbsoluteDollarVariance`)}
                      primaryText={'$' + getValidNumber(absoluteDollarVariance.toFixed(2))}
                      width="100%"
                    />
                  </View.Item>
                </View>
              </View>
              <View direction="column">
                <View direction="column">
                  <View>
                    <Text weight="bold" color="500">
                      {t(`CycleCount.AccuracyMeasurementsCard.PIECES`)}
                    </Text>
                  </View>
                </View>
                <View direction="row" padding={[4, 0]} gap={2} width="100%" align="center">
                  <View.Item grow>
                    <Stat
                      title={t(`CycleCount.AccuracyMeasurementsCard.NetPieceAccuracy`)}
                      primaryText={getValidPercentage(netPieceAccuracy)}
                      width="100%"
                    />
                  </View.Item>
                  <View.Item grow>
                    <Stat
                      title={t(`CycleCount.AccuracyMeasurementsCard.AbsolutePieceAccuracy`)}
                      primaryText={getValidPercentage(absolutePieceAccuracy)}
                      width="100%"
                    />
                  </View.Item>
                </View>
                <View direction="row" gap={2} width="100%" align="center">
                  <View.Item grow className={styles['gray-card']}>
                    <Stat
                      title={t(`CycleCount.AccuracyMeasurementsCard.TotalPiecesCounted`)}
                      primaryText={`${getValidNumber(totalPiecesCounted.toFixed(2))}`}
                      width="100%"
                    />
                  </View.Item>
                  <View.Item grow className={styles['gray-card']}>
                    <Stat
                      title={t(`CycleCount.AccuracyMeasurementsCard.NetPieceVariance`)}
                      primaryText={`${getValidNumber(netPieceVariance.toFixed(2))}`}
                      width="100%"
                    />
                  </View.Item>
                  <View.Item grow className={styles['gray-card']}>
                    <Stat
                      title={t(`CycleCount.AccuracyMeasurementsCard.AbsolutePieceVariance`)}
                      primaryText={`${getValidNumber(absolutePieceVariance.toFixed(2))}`}
                      width="100%"
                    />
                  </View.Item>
                </View>
              </View>
            </View>
          </WidgetWithChildren.Body>
        </WidgetWithChildren>
      </View>
    </View>
  );
};
