/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View } from '@az/starc-ui';

import { CycleCountDetailsHeader } from './CycleCountDetailsComponents/CycleCountDetailsHeader/CycleCountDetailsHeader';
import { CycleCountDetailsTabs } from './CycleCountDetailsComponents/CycleCountDetailsTabs/CycleCountDetailsTabs';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';

export const CycleCountDetails = () => {
  const [progressValue, setProgressValue] = useState(0);
  return (
    <View direction="column" height="100%">
      <CycleCountDetailsHeader progressValue={progressValue} />
      <CycleCountDetailsTabs />
      <Outlet context={[progressValue, setProgressValue]} />
    </View>
  );
};
