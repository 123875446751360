/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { t } from 'i18next';

import {
  Button,
  View,
  Text,
  Icon,
  Add,
  Checkbox,
  Select,
  Dropdown,
  classNames,
  Divider,
} from '@az/starc-ui';
import { SelectOption, SingleValue } from '@az/starc-ui/dist/components/select/Select.types';

import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { QuantityBadge } from '@shared/components/QuantityBadge/QuantityBadge';
import { QuantityVariants } from '@shared/components/QuantityBadge/QuantityBadge.types';
import { Download } from '@shared/assets/icons';
import { CardGridSkeleton } from '@shared/components/Skeletons/CardGridSkeleton';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';
import {
  ENTITY_ASSOCIATIONS,
  MAX_PAGE_SIZE,
  NOTIFICATION_TYPES,
  PAGE_SIZE as DEFAULT_PAGE_SIZE,
} from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import {
  useDownloadReplenishment,
  useGetReplenishment,
} from '@inbound/services/hooks/useGetReplenishment';
import { DropdownOption } from '@inbound/components/DropdownSelectMenu/DropdownSelectMenu.types';
import {
  ALL_TIMES,
  REPLENISHMENT_SORT_TYPES,
  REPLENISHMENT_TYPES,
} from '@inbound/constants/dataConstants';
import { toUTCString } from '@inbound/utils/utils';
import {
  ASC,
  DEFAULT_PAGE,
  FILE_TYPES,
  REPLENISHMENT_ENTITY_ASSOCIATIONS,
  REPLENISHMENT_FILTER_VALUE,
  REPLENISHMENT_SORT_BY,
  REPLENISHMENT_STATUS,
  REPLENISHMENT_TIME,
} from '@inbound/constants/constants';
import { AddManualReplenishmentModal } from '@inbound/components/AddManualReplenishmentModal/AddManualReplenishmentModal';

import { ReplenishmentDashboardCard } from './ReplenishmentDashboardCard';

import styles from './ReplenishmentDashboard.module.scss';
import { GroupBySubzoneAccordion } from './GroupBySubzoneAccordion';
import { DownloadFileType, ReplenishmentMoveType, ReplenishmentType } from '@inbound/types/types';
import { generateDateString } from '@ofm/utils/utils';

export const ReplenishmentDashboard = () => {
  /* State variables */
  const [replenishmentType, setReplenishmentType] = useState<SelectOption>({
    label: t(REPLENISHMENT_TYPES[0].label),
    value: REPLENISHMENT_TYPES[0].value,
  });
  const [toDate, setToDate] = useState('');
  const [checkboxFilter, setCheckboxFilter] = useState<string[]>([
    REPLENISHMENT_FILTER_VALUE.SHOW_6000_REPLENISHMENTS,
  ]);
  const [groupBySubzoneSelected, setGroupBySubzoneSelected] = useState(false);
  const [_7000plusData, set7000plusData] = useState<ReplenishmentMoveType[]>([]);
  const [_7000Data, set7000Data] = useState<ReplenishmentMoveType[]>([]);
  const [_6000Data, set6000Data] = useState<ReplenishmentMoveType[]>([]);
  const [selectedSortByValue, setSelectedSortByValue] = useState<DropdownOption>();

  const [page, setPage] = useState(DEFAULT_PAGE);
  const [addReplenishmentOpen, setAddReplenishmentOpen] = useState(false);
  const [downloadWithFilters, setDownloadWithFilters] = useState<boolean>(true);
  const [openExportDropdown, setOpenExportDropdown] = useState<boolean>(false);
  const [isExportInProgress, setIsExportInProgress] = useState<boolean>(false);
  const [exportType, setExportType] = useState<DownloadFileType>('');
  const [_6000plusloader, set6000plusLoader] = useState<boolean>(true);
  const [_7000plusloader, set7000plusLoader] = useState<boolean>(true);
  const [_7000loader, set7000Loader] = useState<boolean>(true);

  /* Constants */
  const { handleNotification } = useNotificationHandler();
  const hasMoreToLoadRef = useRef(true);

  /* Queries */
  const statusCds =
    replenishmentType.value === REPLENISHMENT_STATUS.ASSIGNED
      ? [
          REPLENISHMENT_STATUS.ASSIGNED,
          REPLENISHMENT_STATUS.IN_PROGRESS,
          REPLENISHMENT_STATUS.SKIPPED,
        ]
      : replenishmentType.value === REPLENISHMENT_STATUS.UNASSIGNED
      ? [REPLENISHMENT_STATUS.NEW, REPLENISHMENT_STATUS.UNASSIGNED]
      : [
          REPLENISHMENT_STATUS.ASSIGNED,
          REPLENISHMENT_STATUS.IN_PROGRESS,
          REPLENISHMENT_STATUS.SKIPPED,
          REPLENISHMENT_STATUS.NEW,
          REPLENISHMENT_STATUS.UNASSIGNED,
        ];

  const { replenishmentData, isLoading } = useGetReplenishment({
    searchPage: {
      page: page,
      size: DEFAULT_PAGE_SIZE,
      sort: [{ sortBy: 'plannedMoveTs', direction: ASC }],
    },
    searchCriteria: {
      ...{ statusCds },
      toDate: toDate ? generateDateString(new Date(toDate), t('DateFormat.CreateOrder')) : '',
      entityAssociations: [
        REPLENISHMENT_ENTITY_ASSOCIATIONS.REPLN_REQUEST,
        REPLENISHMENT_ENTITY_ASSOCIATIONS.REPLN_ASSIGNED_USERS,
        REPLENISHMENT_ENTITY_ASSOCIATIONS.REPLN_ASSETS,
      ],
      replnAssignedUser: {
        userStatusCd: [],
      },
    },
  });

  const { refetch: downloadFile } = useDownloadReplenishment(
    {
      searchPage: {
        page: DEFAULT_PAGE,
        size: MAX_PAGE_SIZE,
        sort: [{ sortBy: 'plannedMoveTs', direction: ASC }],
      },

      searchCriteria: {
        ...(downloadWithFilters
          ? statusCds
          : [
              REPLENISHMENT_STATUS.ASSIGNED,
              REPLENISHMENT_STATUS.NEW,
              REPLENISHMENT_STATUS.SKIPPED,
              REPLENISHMENT_STATUS.IN_PROGRESS,
              REPLENISHMENT_STATUS.UNASSIGNED,
            ]),
        ...(downloadWithFilters &&
          toDate && { toDate: generateDateString(new Date(toDate), t('DateFormat.CreateOrder')) }),

        entityAssociations: [
          ENTITY_ASSOCIATIONS.REPLEN_REQUEST,
          REPLENISHMENT_ENTITY_ASSOCIATIONS.REPLN_ASSIGNED_USERS,
          REPLENISHMENT_ENTITY_ASSOCIATIONS.REPLN_ASSETS,
        ],
      },
      type: exportType === FILE_TYPES.PDF ? 'application/pdf' : 'application/csv',
    },
    false
  );

  /* Functions */
  const renderSubtitle = () => {
    return (
      <View direction="row" gap={6}>
        <Text>{t('ReplenishmentDashboard.MasterTitle.Total', { count: 65 })}</Text>
        <Text>{t('ReplenishmentDashboard.MasterTitle.7000+', { count: 23 })}</Text>
        <Text>{t('ReplenishmentDashboard.MasterTitle.7000', { count: 42 })}</Text>
        <Text>{t('ReplenishmentDashboard.MasterTitle.6000+', { count: 65 })}</Text>
        <Text>{t('ReplenishmentDashboard.MasterTitle.Over24Hours', { hours: 42 })}</Text>
      </View>
    );
  };

  const onTimeChange = (option: SelectOption) => {
    if (option.value === REPLENISHMENT_TIME.OVER24HRS) {
      const datetime = new Date().setSeconds(0) - 86400000;
      setToDate(toUTCString(new Date(datetime)));
    } else {
      setToDate('');
    }
  };

  const calculateOuts = (cost: number | null, qty: number | null) =>
    cost && qty && !isNaN(+qty) && !isNaN(+cost) ? +cost * +qty : 0;

  const getSlot = (replenishmentMove: ReplenishmentMoveType) => {
    return replenishmentMove.toLayoutName ?? '';
  };
  const onGroupBySubzone = () => {
    setGroupBySubzoneSelected(!groupBySubzoneSelected);
  };

  const groupBySubzone = (data: ReplenishmentMoveType[]) =>
    data.reduce(
      (result, item) => {
        const group = result[
          item.replnRequest.parentLayoutName == null ? 'null' : item.replnRequest.parentLayoutName
        ] || {
          slotQty: 0,
          currentBaseCostAmt: 0,
          totalOuts: 0,
          items: [],
          totalOriginalReplenQty: 0,
        };
        group.items.push(item);
        group.slotQty += +(item.replnRequest.slotQty ?? 0);
        group.currentBaseCostAmt += +(item.replnRequest.currentBaseCostAmt ?? 0);
        group.totalOuts += calculateOuts(
          item.replnRequest.currentBaseCostAmt,
          item.replnRequest.slotQty
        );
        group.totalOriginalReplenQty += item.replnRequest.originalReplnQty ?? 0;
        result[
          item.replnRequest.parentLayoutName == null ? 'null' : item.replnRequest.parentLayoutName
        ] = group;

        return result;
      },
      {} as Record<
        string,
        {
          slotQty: number;
          currentBaseCostAmt: number;
          totalOuts: number;
          items: ReplenishmentMoveType[];
          totalOriginalReplenQty: number;
        }
      >
    );

  const _7000plusGroupBySubzone = _7000plusData ? groupBySubzone(_7000plusData) : {};
  const _7000GroupBySubzone = _7000Data ? groupBySubzone(_7000Data) : {};
  const _6000GroupBySubzone = _6000Data ? groupBySubzone(_6000Data) : {};

  const handleScroll = useCallback((scrollElement: HTMLElement) => {
    if (scrollElement.getAttribute('data-testid') == 'dashboard_6000-plus') {
      set7000plusLoader(false);
      set7000Loader(false);
      set6000plusLoader(true);
    }
    if (scrollElement.getAttribute('data-testid') == 'dashboard_7000-plus') {
      set7000Loader(false);
      set6000plusLoader(false);
      set7000plusLoader(true);
    }
    if (scrollElement.getAttribute('data-testid') == 'dashboard_7000') {
      set7000plusLoader(false);
      set6000plusLoader(false);
      set7000Loader(true);
    }
    if (
      scrollElement &&
      scrollElement.scrollTop + scrollElement.clientHeight >= scrollElement.scrollHeight - 5 &&
      hasMoreToLoadRef.current
    ) {
      setPage((prev) => prev + 1);
      hasMoreToLoadRef.current = false;
    }
  }, []);

  const onSort = useMemo(
    () => ({
      [REPLENISHMENT_SORT_BY.DATE]: (
        a: { replnRequest: ReplenishmentType },
        b: { replnRequest: ReplenishmentType }
      ) =>
        new Date(a.replnRequest.srcRequestedTs || '').getTime() -
        new Date(b.replnRequest.srcRequestedTs || '').getTime(),
      [REPLENISHMENT_SORT_BY.OUTS]: (
        a: { replnRequest: ReplenishmentType },
        b: { replnRequest: ReplenishmentType }
      ) =>
        calculateOuts(b.replnRequest.currentBaseCostAmt, b.replnRequest.slotQty) -
        calculateOuts(a.replnRequest.currentBaseCostAmt, a.replnRequest.slotQty),
      [REPLENISHMENT_SORT_BY.SLOT_QTY]: (
        a: { replnRequest: ReplenishmentType },
        b: { replnRequest: ReplenishmentType }
      ) => (+(a.replnRequest.slotQty ?? 0) || 0) - (+(b.replnRequest.slotQty ?? 0) || 0),
      [REPLENISHMENT_SORT_BY.PIECES]: (
        a: { replnRequest: ReplenishmentType },
        b: { replnRequest: ReplenishmentType }
      ) => (a.replnRequest.totalReplnQty ?? 0) - (b.replnRequest.totalReplnQty ?? 0),
      [REPLENISHMENT_SORT_BY.SOURCE_LOCATION]: (
        a: { replnRequest: ReplenishmentType },
        b: { replnRequest: ReplenishmentType }
      ) => (a.replnRequest.layoutName ?? '').localeCompare(b.replnRequest.layoutName ?? ''),
      [REPLENISHMENT_SORT_BY.SLOT]: (a: ReplenishmentMoveType, b: ReplenishmentMoveType) =>
        getSlot(a).localeCompare(getSlot(b)),
    }),
    []
  );

  const handleSort = (sortBy: REPLENISHMENT_SORT_BY) => {
    REPLENISHMENT_SORT_TYPES.filter((sortByValue) => {
      if (sortByValue.value === sortBy) {
        setSelectedSortByValue({ value: sortByValue.value, label: sortByValue.label });
      }
    });
    const sortedData =
      replenishmentData?.content?.sort((a, b) => onSort[sortBy as keyof typeof onSort](a, b)) ?? [];
    const [sorted_7000plusData, sorted_7000Data, sorted_6000Data] = [
      [],
      [],
      [],
    ] as ReplenishmentMoveType[][];
    sortedData.forEach((item: ReplenishmentMoveType) => {
      const replenishment = item.replnRequest;
      if (replenishment.priority > 7000) {
        sorted_7000plusData.push(item);
      } else if (replenishment.priority === 7000) {
        sorted_7000Data.push(item);
      } else {
        sorted_6000Data.push(item);
      }
    });
    set7000plusData(sorted_7000plusData);
    set7000Data(sorted_7000Data);
    set6000Data(sorted_6000Data);
  };

  const toggleExportDropDown = () => setOpenExportDropdown(!openExportDropdown);

  const toggleDownloadFilter = () => setDownloadWithFilters(!downloadWithFilters);

  const handleDownload = (type: DownloadFileType) => {
    setIsExportInProgress(true);
    setExportType(type);
    toggleExportDropDown();
  };

  const handleReplenishmentTypeChange = (value: SingleValue) => {
    if (value) {
      setReplenishmentType(value);

      // Reset loaders when the replenishment type is changed
      set7000Loader(true);
      set6000plusLoader(true);
      set7000plusLoader(true);
    }
  };

  /* Hooks */

  useEffect(() => {
    if (replenishmentData) {
      const scrollElement7000plus = document.querySelector(
        'div[data-testid="dashboard_7000-plus"]'
      );
      const scrollElement6000 = document.querySelector('div[data-testid="dashboard_6000-plus"]');
      const scrollElement7000 = document.querySelector('div[data-testid="dashboard_7000"]');

      const currentScrollPosition_7000plus = scrollElement7000plus
        ? scrollElement7000plus.scrollHeight
        : 0;
      const currentScrollPosition_6000plus = scrollElement6000 ? scrollElement6000.scrollHeight : 0;

      const currentScrollPosition_7000 = scrollElement7000 ? scrollElement7000.scrollHeight : 0;

      // Declare new arrays instead of mutating the existing ones
      const new6000Data: ReplenishmentMoveType[] = [];
      const new7000Data: ReplenishmentMoveType[] = [];
      const new7000plusData: ReplenishmentMoveType[] = [];

      replenishmentData.content.map((item: ReplenishmentMoveType) => {
        const replenishment = item.replnRequest;

        if (replenishment.priority > 7000) {
          new7000plusData.push(item);
        } else if (replenishment.priority === 7000) {
          new7000Data.push(item);
        } else {
          new6000Data.push(item);
        }
      });

      if (page === 0) {
        // Replace the data when on page 0
        set6000Data(new6000Data);
        set7000Data(new7000Data);
        set7000plusData(new7000plusData);
      } else {
        // Append to existing data for subsequent pages
        set6000Data((prevData) => [...prevData, ...new6000Data]);
        set7000Data((prevData) => [...prevData, ...new7000Data]);
        set7000plusData((prevData) => [...prevData, ...new7000plusData]);

        setTimeout(() => {
          if (scrollElement7000plus) {
            scrollElement7000plus.scrollTop =
              scrollElement7000plus.scrollHeight -
              currentScrollPosition_7000plus -
              scrollElement7000plus.clientHeight;
          }
          if (scrollElement6000) {
            scrollElement6000.scrollTop =
              scrollElement6000.scrollHeight -
              currentScrollPosition_6000plus -
              scrollElement6000.clientHeight;
          }
          if (scrollElement7000) {
            scrollElement7000.scrollTop =
              scrollElement7000.scrollHeight -
              currentScrollPosition_7000 -
              scrollElement7000.clientHeight;
          }
        }, 100);
      }
    }
  }, [page, replenishmentData]);

  useEffect(() => {
    const downloadReplenishment = async () => {
      const downloadData = await downloadFile();

      if (downloadData) {
        if (downloadData.data && !downloadData.data.status) {
          setExportType('');
          setIsExportInProgress(false);
          handleNotification(NOTIFICATION_TYPES.ERROR, t('Errors.Action.Export', { item: '' }));
        } else {
          setIsExportInProgress(false);
          setExportType('');
          handleNotification(NOTIFICATION_TYPES.SUCCESS, t('Success.Action.Export'));
        }
      }
    };
    if (exportType !== '') {
      downloadReplenishment();
    }
  }, [downloadFile, exportType, handleNotification]);

  useEffect(() => {
    const scrollElement_7000plus = document.querySelector('div[data-testid="dashboard_7000-plus"]');
    const scrollElement_6000 = document.querySelector('div[data-testid="dashboard_6000-plus"]');
    const scrollElement_7000 = document.querySelector('div[data-testid="dashboard_7000"]');
    scrollElement_7000?.addEventListener('scroll', () =>
      handleScroll(scrollElement_7000 as HTMLElement)
    );
    scrollElement_7000plus?.addEventListener('scroll', () =>
      handleScroll(scrollElement_7000plus as HTMLElement)
    );
    scrollElement_6000?.addEventListener('scroll', () =>
      handleScroll(scrollElement_6000 as HTMLElement)
    );
    return () => {
      scrollElement_7000plus?.removeEventListener('scroll', () =>
        handleScroll(scrollElement_7000plus as HTMLElement)
      );
      scrollElement_6000?.removeEventListener('scroll', () =>
        handleScroll(scrollElement_6000 as HTMLElement)
      );
      scrollElement_7000?.removeEventListener('scroll', () =>
        handleScroll(scrollElement_7000 as HTMLElement)
      );
    };
  }, [handleScroll]);

  useEffect(() => {
    if (replenishmentData) {
      hasMoreToLoadRef.current = true;
    }
  }, [replenishmentData]);

  return (
    <View direction="column" backgroundColor="secondary" className={styles['dashboard']}>
      <MasterTitle
        title={t('ReplenishmentDashboard.MasterTitle.Title')}
        subtitle={renderSubtitle()}
        // TODO: Add functionality to save page to favorites column
        titleActionProps={{
          label: t('MasterTitle.Favourite'),
          handleClick: () => {
            return;
          },
        }}
      >
        <View direction="row" justify="end" align="center" gap={4}>
          <View.Item>
            <Button size="large">
              <View direction="row" align="center" justify="center" gap={2}>
                <Icon svg={Add} color="on-primary" />
                <Text>{t('ReplenishmentDashboard.MasterTitle.AssignByVehicle')}</Text>
              </View>
            </Button>
          </View.Item>
          <View.Item>
            <Dropdown
              className={styles['po-dashboard__download_content']}
              padding={2}
              variant="ghost"
              open={openExportDropdown}
              width={300}
            >
              <Dropdown.Button
                className={styles['dashboard__download_content__button']}
                onClick={toggleExportDropDown}
                disabled={isExportInProgress || isLoading}
              >
                {isExportInProgress ? (
                  <Button loading={true} variant="ghost" />
                ) : (
                  <View direction="row" align="center" justify="center" gap={2}>
                    <Icon svg={Download} />
                    <Text size="100" weight="bold">
                      {t('InboundDashboard.Export.Title')}
                    </Text>
                  </View>
                )}
              </Dropdown.Button>
              <Dropdown.Content>
                <View gap={2} padding={3}>
                  <View
                    direction="row"
                    align="center"
                    gap={2}
                    className={styles['dashboard__download_content__button-details']}
                    as="div"
                    attributes={{
                      onClick: () => handleDownload(FILE_TYPES.CSV),
                    }}
                  >
                    <Icon svg={Download} />
                    <Text size="075" weight="bold">
                      {t('InboundDashboard.Export.CSV')}
                    </Text>
                  </View>
                  <View
                    direction="row"
                    align="center"
                    gap={2}
                    className={styles['dashboard__download_content__button-details']}
                    as="div"
                    attributes={{
                      onClick: () => handleDownload(FILE_TYPES.PDF),
                    }}
                  >
                    <Icon svg={Download} />
                    <Text size="075" weight="bold">
                      {t('InboundDashboard.Export.PDF')}
                    </Text>
                  </View>
                  <View>
                    <Divider blank color="300" />
                  </View>
                  <View direction="row" gap={2} align="center">
                    <Checkbox
                      checked={downloadWithFilters}
                      label={t('InboundDashboard.Export.FilterTitle')}
                      value="Exportfilters"
                      name="Exportfilters"
                      onChange={() => toggleDownloadFilter()}
                    />
                  </View>
                </View>
              </Dropdown.Content>
            </Dropdown>
          </View.Item>
          <View.Item>
            <Button size="large" onClick={() => setAddReplenishmentOpen(true)}>
              <View direction="row" align="center" justify="center" gap={2}>
                <Icon svg={Add} color="on-primary" />
                <Text>{t('ReplenishmentDashboard.MasterTitle.AddReplenishment')}</Text>
              </View>
            </Button>
          </View.Item>
        </View>
      </MasterTitle>

      <View
        direction="row"
        padding={[4, 6]}
        justify="space-between"
        backgroundColor="secondary"
        align="center"
        className={styles['dashboard__filter-wrapper']}
      >
        <View.Item grow>
          <View direction="row" gap={4} align="center">
            <View.Item columns={5}>
              <View direction="row" gap={2}>
                <View.Item columns={7}>
                  <Select
                    label=""
                    variant="no-label"
                    id="replenishmentType"
                    placeholder={t('ReplenishmentDashboard.Filter.AllReplenishments')}
                    name="replenishmentType"
                    value={replenishmentType}
                    options={REPLENISHMENT_TYPES.map(({ label, value }: DropdownOption) => ({
                      label: t(label),
                      value: value,
                    }))}
                    onValueChange={(value) => handleReplenishmentTypeChange(value)}
                  />
                </View.Item>
                <View.Item columns={5}>
                  <Select
                    label=""
                    variant="no-label"
                    id="replenishmentTime"
                    placeholder={t('ReplenishmentDashboard.Filter.AllTimes')}
                    name="replenishmentTime"
                    options={ALL_TIMES.map(({ label, value }: DropdownOption) => ({
                      label: t(label),
                      value: value,
                    }))}
                    onValueChange={(value) => value && onTimeChange(value)}
                  />
                </View.Item>
              </View>
            </View.Item>

            <View.Item columns={7}>
              <Checkbox.Group
                name="replenishmentFilters"
                onValueChange={(value) => setCheckboxFilter(value)}
                value={checkboxFilter}
              >
                <View
                  direction="row"
                  gap={4}
                  align="center"
                  padding={[2, 0, 0, 0]}
                  attributes={{ 'data-type': 'checkbox-group' }}
                >
                  <View.Item>
                    <Checkbox
                      key="groupBySubzone"
                      label={t('ReplenishmentDashboard.Filter.GroupBySubzone')}
                      name="groupBySubzone"
                      value={REPLENISHMENT_FILTER_VALUE.GROUP_BY_SUBZONE}
                      onChange={() => onGroupBySubzone()}
                    />
                    <Checkbox
                      key="gridView"
                      label={t('ReplenishmentDashboard.Filter.GridView')}
                      name="gridView"
                      value={REPLENISHMENT_FILTER_VALUE.GRID_VIEW}
                    />
                  </View.Item>
                  <View.Item>
                    <Checkbox
                      key="countBySubzone"
                      label={t('ReplenishmentDashboard.Filter.CountBySubzone')}
                      name="countBySubzone"
                      value={REPLENISHMENT_FILTER_VALUE.COUNT_By_SUBZONE}
                    />
                    <Checkbox
                      key="countByPriority"
                      label={t('ReplenishmentDashboard.Filter.CountByPriority')}
                      name="countByPriority"
                      value={REPLENISHMENT_FILTER_VALUE.COUNT_By_PRIORITY}
                    />
                  </View.Item>
                  <View.Item>
                    <Checkbox
                      key="show6000Replenishment"
                      label={t('ReplenishmentDashboard.Filter.Show6000Replenishment')}
                      name="show6000Replenishment"
                      value={REPLENISHMENT_FILTER_VALUE.SHOW_6000_REPLENISHMENTS}
                    />
                    <Checkbox
                      key="zonersInReplenishments"
                      label={t('ReplenishmentDashboard.Filter.ZonersInReplenishments')}
                      name="zonersInReplenishments"
                      value={REPLENISHMENT_FILTER_VALUE.ZONERS_IN_REPLENISHMENTS}
                    />
                  </View.Item>
                </View>
              </Checkbox.Group>
            </View.Item>
          </View>
        </View.Item>

        <View.Item>
          <View width="300px">
            <Dropdown className={styles['dashboard__dropdown']}>
              <Dropdown.Button
                className={styles['dashboard__dropdown__button']}
                label={
                  selectedSortByValue
                    ? t(selectedSortByValue.label)
                    : t('SortByOptions.SortByDateOldest')
                }
              />
              <Dropdown.Content>
                <View>
                  {REPLENISHMENT_SORT_TYPES.map((sortByValue) => (
                    <Dropdown.Item
                      key={sortByValue.value}
                      className={classNames(
                        selectedSortByValue?.value === sortByValue.value &&
                          styles['dashboard__dropdown__item']
                      )}
                      label={t(sortByValue.label)}
                      onClick={() => handleSort(sortByValue.value)}
                    />
                  ))}
                </View>
              </Dropdown.Content>
            </Dropdown>
          </View>
        </View.Item>
      </View>

      <View
        direction="row"
        padding={[0, 6]}
        backgroundColor="secondary"
        className={styles['dashboard__board-wrapper']}
      >
        <View.Item
          columns={{
            s: 12,
            m: 12,
            l: 6,
            xl: 4,
          }}
          grow
        >
          <View gap={2} direction="row" padding={[4, 0]} align="center">
            <QuantityBadge
              variant={QuantityVariants.SELECTED}
              text={String(_7000plusData?.length)}
            />
            <View.Item>
              <Text weight="bold" size="125">
                {t('ReplenishmentDashboard.7000+')}
              </Text>
            </View.Item>
          </View>
          <View
            direction="column"
            gap={2}
            padding={[4, 4]}
            className={styles['dashboard_7000-plus']}
            attributes={{
              'data-testid': 'dashboard_7000-plus',
            }}
          >
            {groupBySubzoneSelected ? (
              Object.keys(_7000plusGroupBySubzone).every((key) => key === 'null') ? (
                <View>
                  <Text>{t('ReplenishmentDashboard.NoSubzoneFoundText')}</Text>
                </View>
              ) : (
                Object.entries(_7000plusGroupBySubzone || {}).map(([subzone, replenishments]) => (
                  <GroupBySubzoneAccordion
                    key={subzone}
                    subzone={subzone}
                    replenishments={{
                      items: replenishments.items,
                      originalReplenQty: replenishments.totalOriginalReplenQty,
                      outs: replenishments.totalOuts,
                    }}
                    type="ReplenishmentPriority.SevenThousandPlus"
                  />
                ))
              )
            ) : _7000plusData.length === 0 ? (
              <View>
                {isLoading && _7000plusloader ? (
                  <CardGridSkeleton />
                ) : (
                  <View>
                    <Text>{t('ReplenishmentDashboard.NoDataFoundText')}</Text>
                  </View>
                )}
              </View>
            ) : (
              <>
                {isLoading && _7000plusloader ? (
                  <View>
                    <CardGridSkeleton />
                  </View>
                ) : (
                  _7000plusData?.map((data) => (
                    <ReplenishmentDashboardCard
                      data={data}
                      statusVariant={StatusVariants.IN_PROGRESS}
                    />
                  ))
                )}
              </>
            )}
          </View>
        </View.Item>

        <View.Item
          columns={{
            s: 12,
            m: 12,
            l: 6,
            xl: 4,
          }}
          grow
        >
          <View gap={2} direction="row" padding={[4, 0]} align="center">
            <QuantityBadge variant={QuantityVariants.SELECTED} text={String(_7000Data?.length)} />
            <View.Item>
              <Text weight="bold" size="125">
                {t('ReplenishmentDashboard.7000')}
              </Text>
            </View.Item>
          </View>
          <View
            attributes={{
              'data-testid': 'dashboard_7000',
            }}
            direction="column"
            gap={2}
            padding={[4, 4]}
            className={styles['dashboard_7000']}
          >
            {groupBySubzoneSelected ? (
              Object.keys(_7000GroupBySubzone).every((key) => key === 'null') ? (
                <View>
                  <Text>{t('ReplenishmentDashboard.NoSubzoneFoundText')}</Text>
                </View>
              ) : (
                Object.entries(_7000GroupBySubzone || {}).map(
                  ([subzone, replenishments]) =>
                    _7000GroupBySubzone && (
                      <GroupBySubzoneAccordion
                        key={subzone}
                        subzone={subzone}
                        replenishments={{
                          items: replenishments.items,
                          originalReplenQty: replenishments.totalOriginalReplenQty,
                          outs: replenishments.currentBaseCostAmt * replenishments.slotQty,
                        }}
                        type="ReplenishmentPriority.SevenThousand"
                      />
                    )
                )
              )
            ) : _7000Data.length === 0 ? (
              <View>
                {isLoading && _7000loader ? (
                  <CardGridSkeleton />
                ) : (
                  <View>
                    <Text>{t('ReplenishmentDashboard.NoDataFoundText')}</Text>
                  </View>
                )}
              </View>
            ) : (
              <>
                {isLoading && _7000loader ? (
                  <View>
                    <CardGridSkeleton />
                  </View>
                ) : (
                  _7000Data?.map((data) => (
                    <ReplenishmentDashboardCard
                      data={data}
                      statusVariant={StatusVariants.READY_FOR_ACTION}
                    />
                  ))
                )}
              </>
            )}
          </View>
        </View.Item>
        {checkboxFilter.includes(REPLENISHMENT_FILTER_VALUE.SHOW_6000_REPLENISHMENTS) && (
          <View.Item
            columns={{
              s: 12,
              m: 12,
              l: 6,
              xl: 4,
            }}
            grow
          >
            <View gap={2} direction="row" padding={[4, 0]} align="center">
              <QuantityBadge variant={QuantityVariants.SELECTED} text={String(_6000Data?.length)} />
              <View.Item>
                <Text weight="bold" size="125">
                  {t('ReplenishmentDashboard.6000+')}
                </Text>
              </View.Item>
            </View>
            <View
              direction="column"
              gap={2}
              padding={[4, 4]}
              className={styles['dashboard_6000-plus']}
              attributes={{
                'data-testid': 'dashboard_6000-plus',
              }}
            >
              {groupBySubzoneSelected ? (
                Object.keys(_6000GroupBySubzone).every((key) => key === 'null') ? (
                  <View>
                    <Text>{t('ReplenishmentDashboard.NoSubzoneFoundText')}</Text>
                  </View>
                ) : (
                  Object.entries(_6000GroupBySubzone || {}).map(([subzone, replenishments]) => (
                    <GroupBySubzoneAccordion
                      key={subzone}
                      subzone={subzone}
                      replenishments={{
                        items: replenishments.items,
                        originalReplenQty: replenishments.totalOriginalReplenQty,
                        outs: replenishments.totalOuts,
                      }}
                      type="ReplenishmentPriority.SixThousand"
                    />
                  ))
                )
              ) : _6000Data.length === 0 ? (
                <View>
                  {isLoading && _6000plusloader ? (
                    <CardGridSkeleton />
                  ) : (
                    <View>
                      <Text>{t('ReplenishmentDashboard.NoDataFoundText')}</Text>
                    </View>
                  )}
                </View>
              ) : (
                <>
                  {isLoading && _6000plusloader ? (
                    <View>
                      <CardGridSkeleton />
                    </View>
                  ) : (
                    _6000Data?.map((data) => (
                      <ReplenishmentDashboardCard
                        data={data}
                        statusVariant={StatusVariants.NOT_STARTED}
                      />
                    ))
                  )}
                </>
              )}
            </View>
          </View.Item>
        )}
      </View>
      {addReplenishmentOpen && (
        <AddManualReplenishmentModal
          isOpen={addReplenishmentOpen}
          onClose={() => setAddReplenishmentOpen(false)}
          onSubmit={() => setAddReplenishmentOpen(false)}
        />
      )}
    </View>
  );
};
