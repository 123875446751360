/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  useEffect,
  useMemo,
  useRef,
  useState,
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Actionable,
  Button,
  View,
  Text,
  Dropdown,
  Icon,
  Checkbox,
  Divider,
  Close,
  Select,
  SingleValue,
  SearchBar,
} from '@az/starc-ui';

import { Download } from '@shared/assets/icons';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { Stat } from '@shared/components/Stat/Stat';
import { Table } from '@shared/components/Table/Table';
import { TableStylingVariants } from '@shared/components/Table/tableConstants';
import { SortRowsParam } from '@shared/components/Table/Table.types';
import {
  COMMODITY_TYPE,
  COMMON,
  DEFAULT_PAGE,
  EMPTY_VALUE,
  INBOUND,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
  PO_TYPE,
} from '@shared/constants/constants';
import { PAGE_URLS } from '@shared/constants/routes';

import { AddPOModal } from '@inbound/components/AddPOModal/AddPOModal';
import { DatePickerWrapper } from '@inbound/components/DatePicker';
import { DropdownOption } from '@inbound/components/DropdownSelectMenu/DropdownSelectMenu.types';

import {
  ASC,
  DEFAULT_SORT_BY,
  DOMAIN_TYPE_CD,
  FILE_TYPES,
  Lengths,
  PO_HISTORY_SEARCH_BY,
  TRAILER_ORDER_STATUS,
  TRAILER_ORDER_STATUS_CDS,
} from '@inbound/constants/constants';
import { PO_HISTORY_TABLE_COLUMNS } from '@inbound/constants/tableConstants';
import { useGetTrailerOrders } from '@inbound/services/hooks/useGetTrailerOrders';
import {
  DownloadFileType,
  PODashboardDataRowType,
  TrailerOrderListType,
} from '@inbound/types/types';
import { endDateRegex, startDateRegex } from '@inbound/utils/regex';
import { calculatePercentageWithSign, mapUItoDBField } from '@inbound/utils/utils';
import { mapPOHistoryTableRows, mapTrailerOrders } from '@inbound/utils/table/tableUtils';

import styles from '../PODashboard.module.scss';
import { DropdownSelectMenu } from '@inbound/components/DropdownSelectMenu/DropdownSelectMenu';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig';
import { PO_HISTORY_SEARCH_BY_OPTIONS } from '@inbound/constants/dataConstants';
import { pdf } from '@react-pdf/renderer';
import { downloadGeneratedCSV, generateDateString } from '@ofm/utils/utils';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { POHistoryExport } from './POHistoryExport';
import { KEY, KEY_CODE } from '@shared/constants/keyConstants';
import { useGetShifts } from '@mdm/services/hooks/useGetShifts';
import { ShiftSchemaType } from '@mdm/schemas/shiftSchema';

export const POHistory = () => {
  /* State variables */
  const [showAddPOModal, setShowAddPOModal] = useState(false);
  const [poData, setPOData] = useState<PODashboardDataRowType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<string>('');
  const [direction, setDirection] = useState<string>('ASC');
  const [totalPage, setTotalPage] = useState<number>(0);
  const [poTypes, setPOTypes] = useState<DropdownOption[]>([]);
  const [commodityTypes, setCommodityTypes] = useState<DropdownOption[]>([]);
  const [downloadWithFilters, setDownloadWithFilters] = useState<boolean>(true);
  const [openExportDropdown, setOpenExportDropdown] = useState<boolean>(false);
  const [isExportInProgress, setIsExportInProgress] = useState<boolean>(false);
  const [, setIsPDFDownloading] = useState<boolean>(false);
  const [exportType, setExportType] = useState<DownloadFileType>('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchBy, setSearchBy] = useState<SingleValue>(PO_HISTORY_SEARCH_BY_OPTIONS[0]);
  const [searchValue, setSearchValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [shiftTypes, setShiftTypes] = useState<SingleValue | undefined>();
  const [selectedShift, setSelectedShift] = useState<ShiftSchemaType[]>([]);
  const [startDateError, setStartDateError] = useState<string>('');
  const [endDateError, setEndDateError] = useState<string>('');
  const [startDateFormatted, setStartDateFormatted] = useState('');
  const [endDateFormatted, setEndDateFormatted] = useState('');
  const [isEndDateDisabled, setIsEndDateDisabled] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const [allShifts, setAllShifts] = useState<ShiftSchemaType[]>([]);

  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breadcrumbs = {
    data: [
      {
        label: t('PODashboard.Title'),
        onClick: () => navigate(PAGE_URLS.PO_DASHBOARD),
      },
      {
        label: t('PODashboard.POHistory.Title'),
        onClick: () => navigate(''),
      },
    ],
  };
  const { handleNotification } = useNotificationHandler();
  const shiftDate = useMemo(() => generateDateString(new Date(), t('DateFormat.CreateOrder')), []);
  const dateBefore25Year = generateDateString(
    new Date(new Date().setFullYear(new Date().getFullYear() - 25)),
    'MM/DD/YYYY 00:00'
  );

  /* Queries */
  const { configsData: PO_TYPES } = useGetFacilityConfig({
    domainCd: INBOUND,
    subDomainCd: COMMON,
    configCd: PO_TYPE,
  });
  const { configsData: COMMODITY_TYPES } = useGetFacilityConfig({
    domainCd: INBOUND,
    subDomainCd: COMMON,
    configCd: COMMODITY_TYPE,
  });

  /**
   * searchPage: Current Page, Page Size, SortBy, Direction (ASC, DESC)
   * searchCriteria:
   *   userAssignState: 'BOTH', 'ASSIGNED', 'UNASSIGNED'
   *   arrivalTimestamps: [{ startTime, endTime }]
   *   orderTypes: LTD, PO, ...
   *   commodityTypes: AO, ...
   *   orderStatus: 'NOT_STARTED', 'READY_FOR_SIGNATURE', ...
   * hooks last 2 parameters isEnabled & isDelay (for debounce), so it need to be passed here
   */
  const {
    trailerOrdersData,
    isLoading,
    isFetching: isFetchingTableData,
  } = useGetTrailerOrders(
    {
      searchPage: {
        currentPage: currentPage - 1,
        pageSize: PAGE_SIZE,
        sortBy: mapUItoDBField(sortBy ? sortBy : DEFAULT_SORT_BY),
        direction: direction.toUpperCase(),
      },
      searchCriteria: {
        inboundOrder: {
          domainTypeCd: DOMAIN_TYPE_CD,
          orderTypeCds: poTypes.map((item) => item.value.toUpperCase()),
          ...(searchBy?.value === PO_HISTORY_SEARCH_BY.VENDOR_NAME &&
            searchQuery !== '' && { vendorName: searchQuery }),
          statusCds: [TRAILER_ORDER_STATUS.FINALIZED],
          finalizedStartTs:
            selectedShift.length > 0 && selectedShift[0].shiftId !== 'custom'
              ? `${shiftDate}T${selectedShift[0].fromTime}:00.000Z`
              : new Date(new Date(startDateFormatted).setHours(0, 0, 0, 0)),
          finalizedEndTs:
            selectedShift.length > 0 && selectedShift[0].shiftId !== 'custom'
              ? `${shiftDate}T${selectedShift[0].toTime}:00.000Z`
              : new Date(new Date(endDateFormatted).setHours(23, 59, 0, 0)),
          user: {
            userStatusCds: ['BOTH'],
          },
        },
        trailerOrder: {
          statusCds: [TRAILER_ORDER_STATUS_CDS.OPEN, TRAILER_ORDER_STATUS_CDS.CLOSED],
          commodityTypeCds: commodityTypes.map((item) => item.value),
        },
        ...(searchQuery !== '' &&
          searchBy?.value !== PO_HISTORY_SEARCH_BY.FINALIZED_PO_NUMBER &&
          searchBy?.value !== PO_HISTORY_SEARCH_BY.VENDOR_NAME && {
            inboundOrderLine: {
              ...(searchBy?.value === PO_HISTORY_SEARCH_BY.SKU && { productId: searchQuery }),
              ...(searchBy?.value === PO_HISTORY_SEARCH_BY.PART_NUMBER && {
                partNbrId: searchQuery,
              }),
            },
          }),
      },
    },
    true,
    true,
    true
  );

  const {
    trailerOrdersData: trailerOrdersSearchData,
    isLoading: isSearchLoading,
    refetch: refetchSearchData,
  } = useGetTrailerOrders(
    {
      searchPage: {
        currentPage: DEFAULT_PAGE - 1,
        pageSize: PAGE_SIZE,
        sortBy: mapUItoDBField(DEFAULT_SORT_BY),
        direction: ASC,
      },
      searchCriteria: {
        inboundOrder: {
          domainTypeCd: DOMAIN_TYPE_CD,
          sourceOrderNbr: searchQuery,
          statusCds: [TRAILER_ORDER_STATUS.FINALIZED],
        },
      },
    },
    false
  );

  const {
    trailerOrdersData: downloadTrailerOrders,
    refetch: fetchDownloadOrders,
    isFetching,
  } = useGetTrailerOrders(
    {
      searchPage: {
        currentPage: 0,
        pageSize: trailerOrdersData ? trailerOrdersData?.totalElements : 0,
        sortBy: mapUItoDBField(sortBy ? sortBy : DEFAULT_SORT_BY),
        direction: direction.toUpperCase(),
      },
      searchCriteria: {
        inboundOrder: {
          domainTypeCd: DOMAIN_TYPE_CD,
          orderTypeCds: poTypes.map((item) => item.value.toUpperCase()),
          statusCds: [TRAILER_ORDER_STATUS.FINALIZED],
          finalizedStartTs:
            selectedShift.length > 0
              ? `${shiftDate}T${selectedShift[0].fromTime}:00.000Z`
              : new Date(new Date(startDateFormatted).setHours(0, 0, 0, 0)),
          finalizedEndTs:
            selectedShift.length > 0
              ? `${shiftDate}T${selectedShift[0].toTime}:00.000Z`
              : new Date(new Date(endDateFormatted).setHours(0, 0, 0, 0)),
          user: {
            userStatusCds: ['BOTH'],
          },
        },
        trailerOrder: {
          statusCds: [TRAILER_ORDER_STATUS_CDS.OPEN, TRAILER_ORDER_STATUS_CDS.CLOSED],
          commodityTypeCds: commodityTypes.map((item) => item.value),
        },
      },
    },
    false,
    false,
    downloadWithFilters,
    true
  );

  const { shiftsData } = useGetShifts(
    {
      shiftDate: shiftDate,
    },
    true
  );

  const { shiftsData: allShiftsData } = useGetShifts({});
  /* Functions */
  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleDopdownButtonClick = (
    e: ReactKeyboardEvent<HTMLElement> | ReactMouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    toggleDropdown();
  };

  const onCloseAddPOModal = () => {
    setShowAddPOModal(false);
  };

  const handlePOTypeFilterChange = (values: DropdownOption[]) => {
    setCurrentPage(1);
    setPOTypes(values);
  };

  const handleCommodityTypeFilterChange = (values: DropdownOption[]) => {
    setCurrentPage(1);
    setCommodityTypes(values);
  };

  const handleShiftTypeFilterChange = (label: string, value: string) => {
    setIsEndDateDisabled(true);
    setStartDate('');
    setEndDate('');

    setShiftTypes({ value: value, label: label });

    const selectedShift =
      shiftsData
        ?.filter((shift) => label === shift.shiftDesc)
        .map((shift) => ({
          shiftId: shift.shiftId || '',
          shiftDesc: shift.shiftDesc ?? '',
          shiftIndex: shift.shiftIndex ?? 0,
          locale: shift.locale ?? '',
          fromTime: shift.fromTime ?? '',
          toTime: shift.toTime ?? '',
          dayOfWeek: shift.dayOfWeek ?? '',
        })) ?? [];
    setSelectedShift(selectedShift);
  };

  const removeShiftType = () => {
    setShiftTypes(undefined);
    setStartDate('');
    setEndDate('');
    setIsEndDateDisabled(true);
    setSelectedShift([]);
    setStartDateFormatted('');
  };

  const isFiltersApplied = () => {
    return Boolean(
      poTypes?.length ||
        commodityTypes?.length ||
        shiftTypes ||
        startDate ||
        endDate ||
        selectedShift.length
    );
  };

  const onClearAll = () => {
    handlePOTypeFilterChange([]);
    handleCommodityTypeFilterChange([]);
    removeShiftType();
    setStartDateError('');
    setEndDateError('');
  };

  const onRemoveFilter = (
    filters: DropdownOption[],
    handleFilterChange: (values: DropdownOption[]) => void,
    index: number
  ) => {
    const result = [...filters];
    result.splice(index, 1);
    handleFilterChange(result);
  };

  const handleTableRowClick = (clickedRow: SortRowsParam) => {
    // Split row id to get source order number & trailer in id
    const ids = clickedRow.id.split('-');

    const sourceOrderNumber = ids[0];
    const receiptId = ids[1];

    navigate(PAGE_URLS.PO_DETAILS(sourceOrderNumber, receiptId));
  };
  const generateOrdersCSVdata = (inputData: PODashboardDataRowType[]) => {
    const Headers = [
      'Arrival Time',
      'Vendor',
      'PO',
      'Door',
      'Location',
      'Lines',
      'Pieces',
      'Commodity',
      'PO Type',
      'SKUs withFPS',
      'FinalizedTime',
      'FinalizedByShift',
      'Status',
    ];

    const CSVData = [];
    CSVData.push(Headers);
    inputData.forEach((order) => {
      CSVData.push([
        generateDateString(order.arrivalTime, t('DateFormat.ShortTime')),
        order.vendorName || EMPTY_VALUE,
        order.poNumber,
        order.door || EMPTY_VALUE,
        order.location || EMPTY_VALUE,
        `${order.lines}/${order.totalLines}`,
        `${order.pieces}/${order.totalPices}`,
        order.commodity,
        order.poType,
        calculatePercentageWithSign(order.skusWithFPS, order.totalSkusWithFPS) || EMPTY_VALUE,
        order.finalizedTs &&
          typeof order.finalizedTs !== 'string' &&
          generateDateString(order.finalizedTs, t('DateFormat.ShortTime')),
        order.finalizedShift &&
          typeof order.finalizedShift !== 'string' &&
          generateDateString(order.finalizedShift, t('DateFormat.ShortTime')),
        order.status,
      ]);
    });

    return CSVData;
  };
  const toggleExportDropDown = () => setOpenExportDropdown(!openExportDropdown);

  const toggleDownloadFilter = () => setDownloadWithFilters(!downloadWithFilters);

  const handleDownload = (type: DownloadFileType) => {
    setIsExportInProgress(true);
    setExportType(type);
    fetchDownloadOrders();
    toggleExportDropDown();
  };

  const downloadPdf = async (ordersData: TrailerOrderListType[]) => {
    setIsPDFDownloading(true);
    const currentDate = new Date();
    try {
      const pdfBlob = await pdf(
        <POHistoryExport data={generateOrdersCSVdata(mapTrailerOrders(ordersData))} />
      ).toBlob();
      handleNotification(NOTIFICATION_TYPES.SUCCESS, t('Success.Action.Export'));
      saveAs(
        pdfBlob,
        `Finalized_PO's_${generateDateString(
          currentDate,
          t('DateFormat.CreateOrderTime').replace(/ /g, '_')
        )}.pdf`
      );
    } catch (err) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Errors.Action.Export', { item: 'orders' }));
    } finally {
      setIsExportInProgress(false);
      setIsPDFDownloading(false);
      setExportType('');
    }
  };

  const onStartDateChange = (value: string) => {
    setIsEndDateDisabled(true);

    if (value.length >= 10 && startDateRegex.test(value)) {
      const previusDate = new Date(dateBefore25Year);
      const startDateValue = new Date(value);
      const currentDate = new Date();

      if (startDateValue >= currentDate) {
        setStartDateError(t('PODashboard.POHistory.ErrorMessage.StartDateError'));
        return;
      } else if (startDateValue < previusDate) {
        setStartDateError(t('PODashboard.POHistory.ErrorMessage.InvalidStartDate'));
        return;
      } else {
        setIsEndDateDisabled(false);
        setStartDateError('');
        setStartDateFormatted(value);
        setShiftTypes({ label: 'Custom', value: 'custom' });

        if (new Date(endDate) < startDateValue) {
          setEndDate(value);
        }
        setStartDate(generateDateString(startDateValue, 'MM/DD/YYYY 00:00'));
        return;
      }
    } else if (value.length >= 10 && !startDateRegex.test(value)) {
      setStartDateError(t('PODashboard.POHistory.ErrorMessage.InvalidStartDate'));
      return;
    }
    if (value.length <= 0) {
      setEndDate('');
      removeShiftType();
    }

    setStartDate(value);
  };

  const onEndDateChange = (value: string) => {
    if (value.length >= 10 && endDateRegex.test(value)) {
      const currentDate = new Date();
      currentDate.setHours(23, 59, 59, 999);
      const endDateValue = new Date(value);
      const startDateValue = new Date(startDate);

      if (endDateValue >= currentDate) {
        setEndDateError(t('PODashboard.POHistory.ErrorMessage.EndDateError'));
        return;
      } else if (endDateValue < startDateValue) {
        setEndDateError(t('PODashboard.POHistory.ErrorMessage.EndDateErrorMsg'));
        return;
      } else {
        setEndDateError('');
        setEndDateFormatted(value);
        setShiftTypes({ label: 'Custom', value: 'custom' });
        if (
          generateDateString(currentDate, 'MM/DD/YYYY 00:00') ===
          generateDateString(endDateValue, 'MM/DD/YYYY 00:00')
        ) {
          setEndDate(generateDateString(new Date(), 'MM/DD/YYYY HH:mm'));
        } else {
          setEndDate(generateDateString(endDateValue, 'MM/DD/YYYY 23:59'));
        }
        return;
      }
    } else if (value.length >= 10 && !endDateRegex.test(value)) {
      setEndDateError(t('PODashboard.POHistory.ErrorMessage.InvalidStartDate'));
      return;
    }

    setEndDate(value);
  };

  const onSearchByChange = (option: SingleValue) => {
    setSearchBy(option);
    onClearSearch();
  };

  const setSearchParam = (param: string) => {
    setSearchQuery(param);
  };

  const onKeyDown = (e: {
    key: string;
    keyCode: number;
    preventDefault: () => void;
    currentTarget: { value: string };
  }) => {
    if (e.key === KEY.ENTER || e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      const inputValue = (e.currentTarget as HTMLInputElement).value;
      setSearchParam(inputValue);

      if (searchBy?.value === PO_HISTORY_SEARCH_BY.FINALIZED_PO_NUMBER) {
        setTimeout(() => {
          refetchSearchData(); // fake the delay to set state
        }, 1);
      }
    }
  };

  const onChange = (e: { target: { value: string } }) => {
    const inputValue = e.target.value;
    if (!inputValue) {
      onClearSearch();
    }
  };

  const onClearSearch = () => {
    setSearchParam('');
    setSearchValue('');
  };

  /*  Hooks */
  useEffect(() => {
    if (shiftsData?.length) {
      const customIndex = shiftsData.findIndex((shift) => shift.shiftId === 'custom');

      if (customIndex === -1) {
        shiftsData.push({
          shiftId: 'custom',
          shiftDesc: 'Custom',
        });
      }
    }
  }, [shiftsData]);

  useEffect(() => {
    if (allShiftsData) {
      setAllShifts(
        allShiftsData.map((shift) => ({
          shiftId: shift.shiftId || '',
          shiftDesc: shift.shiftDesc || '',
          shiftIndex: shift.shiftIndex || 0,
          locale: shift.locale || '',
          fromTime: shift.fromTime || '',
          toTime: shift.toTime || '',
          dayOfWeek: shift.dayOfWeek || '',
        }))
      );
    }
  }, [allShifts, allShiftsData]);

  useEffect(() => {
    if (trailerOrdersData) {
      if (
        trailerOrdersData.totalElements === 0 &&
        (searchBy?.value === PO_HISTORY_SEARCH_BY.SKU ||
          searchBy?.value === PO_HISTORY_SEARCH_BY.PART_NUMBER ||
          searchBy?.value === PO_HISTORY_SEARCH_BY.VENDOR_NAME)
      ) {
        handleNotification(
          NOTIFICATION_TYPES.ERROR,
          t('PODashboard.Notification.SearchTrailerOrder')
        );
      }

      setPOData(mapTrailerOrders(trailerOrdersData.content));
      setTotalPage(Math.ceil(trailerOrdersData.totalElements / PAGE_SIZE));
    }
  }, [trailerOrdersData, isLoading, searchBy?.value, handleNotification, t]);
  useEffect(() => {
    if (trailerOrdersSearchData) {
      if (searchValue && searchValue?.length === 8) {
        if (!trailerOrdersSearchData.empty) {
          const trailerOrderData = trailerOrdersSearchData.content[0].trailerOrder;

          navigate(
            PAGE_URLS.PO_DETAILS(trailerOrderData.sourceOrderNbr, trailerOrderData.receiptId)
          );
        } else {
          handleNotification(
            NOTIFICATION_TYPES.ERROR,
            t('PODashboard.Notification.SearchTrailerOrder')
          );
        }
      }
    }
  }, [trailerOrdersSearchData, isSearchLoading, searchValue, handleNotification, t, navigate]);

  useEffect(() => {
    if (downloadTrailerOrders && !isFetching) {
      if (exportType == FILE_TYPES.CSV) {
        const CSVData = generateOrdersCSVdata(mapTrailerOrders(downloadTrailerOrders.content));
        let csvContent = '';
        const currentDate = new Date();
        CSVData.forEach((row) => {
          csvContent += row.map((element) => element).join(',') + '\n';
        });
        downloadGeneratedCSV(
          {
            fileName: `Finalized_PO's_${generateDateString(
              currentDate,
              t('DateFormat.CreateOrderTime').replace(/ /g, '_')
            )}`,
            data: csvContent,
          },
          'export-order-dashboard'
        );
        setIsExportInProgress(false);
        handleNotification(NOTIFICATION_TYPES.SUCCESS, t('Success.Action.Export'));
        setExportType('');
      } else if (exportType == FILE_TYPES.PDF) {
        downloadPdf(downloadTrailerOrders.content);
      }
    }
  }, [downloadTrailerOrders, isFetching]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      const target: Node = ((event as MouseEvent).target ||
        (event as TouchEvent).targetTouches[0]) as Node;
      if (buttonRef.current && !buttonRef.current.contains(target) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <View direction="column" height="100%" className={styles['po-dashboard']}>
        <MasterTitle
          title={t('PODashboard.POHistory.Title')}
          breadcrumbProps={breadcrumbs}
          // TODO: Add functionality to save page to favorites column
        >
          <View direction="row" justify="space-between" align="center">
            <View.Item>
              <View
                direction="row"
                className={[
                  styles['po-dashboard__statistics-section__statistics'],
                  styles['po-dashboard__history-statistics-section'],
                ]}
              >
                <Stat title={t('PODashboard.POHistory.Stats.TotalFinalized')} primaryText="76" />
                <Stat
                  title={t('PODashboard.POHistory.Stats.RegularPOsFinalized')}
                  primaryText="58"
                />
                <Stat title={t('PODashboard.POHistory.Stats.DSDPOsFinalized')} primaryText="18" />
                <Stat title={t('PODashboard.POHistory.Stats.LTDPOsFinalized')} primaryText="0" />
              </View>
            </View.Item>
            <View.Item grow>
              <View
                direction="row"
                justify="end"
                align="center"
                className={styles['po-dashboard__history-search-wrapper']}
              >
                <View.Item>
                  <View width="230px">
                    <Select
                      label="Search By"
                      name="searchBy"
                      variant="alt"
                      value={{ label: t(searchBy?.label ?? ''), value: searchBy?.value ?? '' }}
                      onValueChange={onSearchByChange}
                      options={PO_HISTORY_SEARCH_BY_OPTIONS.map((option) => ({
                        label: t(option.label),
                        value: option.value,
                      }))}
                    />
                  </View>
                </View.Item>
                <View.Item>
                  <View width="305px">
                    <SearchBar
                      value={searchValue}
                      onValueChange={setSearchValue}
                      suggestions={[]}
                      className={styles['search-bar']}
                      label={t('PODashboard.Search.POHistorySearch', { searchBy: searchBy?.value })}
                      onValueClear={onClearSearch}
                      inputAttributes={{
                        onKeyDown: onKeyDown,
                        onChange: onChange,
                        ...(searchBy?.value === PO_HISTORY_SEARCH_BY.FINALIZED_PO_NUMBER && {
                          maxLength: Lengths.SOURCE_ORDER_NUMBER,
                        }),
                      }}
                      maxMenuHeight={300}
                    />
                  </View>
                </View.Item>

                <View.Item>
                  <Dropdown
                    className={styles['po-dashboard__download_content']}
                    padding={2}
                    variant="ghost"
                    open={openExportDropdown}
                    width={300}
                  >
                    <Dropdown.Button
                      className={styles['po-dashboard__download_content__button']}
                      onClick={toggleExportDropDown}
                      disabled={isExportInProgress || isLoading}
                    >
                      {isExportInProgress ? (
                        <Button loading={true} variant="ghost" />
                      ) : (
                        <View direction="row" align="center" justify="center" gap={2}>
                          <Icon svg={Download} />
                          <Text size="100" weight="bold">
                            {t('InboundDashboard.Export.Title')}
                          </Text>
                        </View>
                      )}
                    </Dropdown.Button>
                    <Dropdown.Content>
                      <View gap={2} padding={3}>
                        <View
                          direction="row"
                          align="center"
                          gap={2}
                          className={styles['po-dashboard__download_content__button-details']}
                          as="div"
                          attributes={{
                            onClick: () => handleDownload(FILE_TYPES.CSV),
                          }}
                        >
                          <Icon svg={Download} />
                          <Text size="075" weight="bold">
                            {t('InboundDashboard.Export.CSV')}
                          </Text>
                        </View>
                        <View
                          direction="row"
                          align="center"
                          gap={2}
                          className={styles['po-dashboard__download_content__button-details']}
                          as="div"
                          attributes={{
                            onClick: () => handleDownload(FILE_TYPES.PDF),
                          }}
                        >
                          <Icon svg={Download} />
                          <Text size="075" weight="bold">
                            {t('InboundDashboard.Export.PDF')}
                          </Text>
                        </View>
                        <View>
                          <Divider blank color="300" />
                        </View>
                        <View direction="row" gap={2} align="center">
                          <Checkbox
                            checked={downloadWithFilters}
                            label={t('InboundDashboard.Export.FilterTitle')}
                            value="Exportfilters"
                            name="Exportfilters"
                            onChange={() => toggleDownloadFilter()}
                          />
                        </View>
                      </View>
                    </Dropdown.Content>
                  </Dropdown>
                </View.Item>
              </View>
            </View.Item>
          </View>
        </MasterTitle>

        <View
          direction="row"
          padding={[4, 6]}
          backgroundColor="secondary"
          className={styles['po-dashboard__filters-section']}
        >
          <View.Item>
            <DropdownSelectMenu
              width={320}
              name="poTypes"
              label="All PO Types"
              value={poTypes}
              options={
                PO_TYPES
                  ? PO_TYPES.map((type) => ({
                      label: `${type.configValue} (${type.description})`,
                      value: type.configValue,
                    }))
                  : []
              }
              onChange={handlePOTypeFilterChange}
              onReset={() => handlePOTypeFilterChange([])}
            />
          </View.Item>
          <View.Item>
            <DropdownSelectMenu
              width={320}
              name="commodityTypes"
              label="All Commodity Types"
              searchable={true}
              value={commodityTypes}
              options={
                COMMODITY_TYPES
                  ? COMMODITY_TYPES.map((item) => ({
                      label: `${item.configValue} (${item.description})`,
                      value: item.configValue,
                    }))
                  : []
              }
              onChange={handleCommodityTypeFilterChange}
              onReset={() => handleCommodityTypeFilterChange([])}
            />
          </View.Item>
          <View.Item>
            <View width="244px">
              <Dropdown open={isOpen}>
                <View attributes={{ ref: buttonRef }}>
                  <Dropdown.Button
                    onClick={handleDopdownButtonClick}
                    label={
                      shiftTypes ? shiftTypes.label : t('PODashboard.POHistory.POFinalizedbyShift')
                    }
                  />
                </View>
                <Dropdown.Content>
                  <View>
                    {shiftsData
                      ? shiftsData.map((item) => (
                          <Dropdown.Item
                            key={item.shiftId}
                            className={
                              shiftTypes && shiftTypes.value === item.shiftId
                                ? styles['po-dashboard__filters-section__selected']
                                : ''
                            }
                            label={item.shiftDesc || ''}
                            onClick={() =>
                              handleShiftTypeFilterChange(item.shiftDesc || '', item.shiftId || '')
                            }
                          />
                        ))
                      : []}
                  </View>
                </Dropdown.Content>
              </Dropdown>
            </View>
          </View.Item>
          <View.Item>
            <View className={styles['po-dashboard__filters-section__date-picker']}>
              <DatePickerWrapper
                id="startDate"
                value={startDate}
                label={t('PODashboard.POHistory.StartDate')}
                dateFormat="MM/DD/YYYY 00:00"
                dateRange={[dateBefore25Year, generateDateString(new Date(), 'MM/DD/YYYY 23:59')]}
                yearRange={50}
                size="small"
                required
                onValueChange={(value) => {
                  onStartDateChange(value);
                }}
              />
              {startDateError && (
                <View direction="row" justify="space-between">
                  <Text size="087" color="error">
                    {startDateError}
                  </Text>
                </View>
              )}
            </View>
          </View.Item>
          <View.Item>
            <View className={styles['po-dashboard__filters-section__date-picker']}>
              <DatePickerWrapper
                id="endDate"
                value={endDate}
                label={t('PODashboard.POHistory.EndDate')}
                dateRange={[startDate, generateDateString(new Date(), 'MM/DD/YYYY 23:59')]}
                yearRange={50}
                size="small"
                dateFormat="MM/DD/YYYY 23:59"
                required
                disabled={isEndDateDisabled}
                onValueChange={(value) => {
                  onEndDateChange(value);
                }}
              />
              {endDateError && (
                <View direction="row" justify="space-between">
                  <Text size="087" color="error">
                    {endDateError}
                  </Text>
                </View>
              )}
            </View>
          </View.Item>
          <View.Item
            attributes={{
              style: { height: '100%' },
            }}
          >
            <View height="40%" justify="center">
              <Actionable onClick={onClearAll} disabled={!isFiltersApplied()}>
                <Text
                  className={styles['po-dashboard__actionable-text']}
                  size="087"
                  weight="medium"
                  variant="text-link"
                >
                  {t('PODashboard.ClearAll')}
                </Text>
              </Actionable>
            </View>
          </View.Item>
        </View>

        <View direction="row" padding={[2, 6]} backgroundColor="secondary">
          {poTypes.length > 0 &&
            poTypes.map((filter, index) => (
              <View className={styles['po-dashboard__filter-item']}>
                <Text size="087">{filter.label}</Text>
                <Actionable
                  onClick={() => {
                    onRemoveFilter(poTypes, handlePOTypeFilterChange, index);
                  }}
                >
                  <Icon svg={Close} size={4} />
                </Actionable>
              </View>
            ))}
          {commodityTypes.length > 0 &&
            commodityTypes.map((filter, index) => (
              <View className={styles['po-dashboard__filter-item']}>
                <Text size="087">{filter.label}</Text>
                <Actionable
                  onClick={() => {
                    onRemoveFilter(commodityTypes, handleCommodityTypeFilterChange, index);
                  }}
                >
                  <Icon svg={Close} size={4} />
                </Actionable>
              </View>
            ))}
          {shiftTypes && (
            <View className={styles['po-dashboard__filter-item']}>
              <Text size="087">{shiftTypes.label}</Text>
              <Actionable onClick={removeShiftType}>
                <Icon svg={Close} size={4} />
              </Actionable>
            </View>
          )}
        </View>

        <View padding={[4, 6]}>
          <Table
            columns={PO_HISTORY_TABLE_COLUMNS}
            // rows={mapPODashboardTableRows(poData, onActionMenuClick)}
            rows={mapPOHistoryTableRows(poData, allShifts)}
            isPaginated={true}
            isCheckboxDisabled={false}
            isApiLoadedData={true}
            pageSize={PAGE_SIZE}
            defaultPage={DEFAULT_PAGE}
            isCreditItem={false}
            isCheckboxTable={false}
            isLoading={isFetchingTableData}
            styleVariant={TableStylingVariants.DETAILS}
            totalPages={totalPage}
            // TODO: implement sorting from the BFF
            onPageChange={(_pageNumber) => {
              setCurrentPage(_pageNumber);
            }}
            onSort={(_sorting, _columnId) => {
              _sorting.forEach((sorting) => {
                if (sorting.id === _columnId) {
                  setSortBy(_columnId);
                  setDirection(sorting.direction);
                }
              });
            }}
            onRowAction={(clickedRow) => handleTableRowClick(clickedRow)}
          />
        </View>
      </View>

      <AddPOModal isOpen={showAddPOModal} onClose={onCloseAddPOModal} />

      <a id="export-order-dashboard" style={{ display: 'none' }} />
    </>
  );
};
