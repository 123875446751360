/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { TopVarianceProductCategoryType } from '@inventory/types/types';

export const QUALITY_CONTROL_SERVICES = [
  {
    title: 'QCS.ScanTotes',
    description: 'QCS.ScanTotesDescription',
    onClickHandler: (searchValue: string) => {
      // Implement the logic for the onClickHandler function
      // eslint-disable-next-line no-console
      console.log(searchValue);
    },
  },
  {
    title: 'QCS.ScanPallets',
    description: 'QCS.ScanPalletsDescription',
    onClickHandler: (searchValue: string) => {
      // Implement the logic for the onClickHandler function
      // eslint-disable-next-line no-console
      console.log(searchValue);
    },
  },
  {
    title: 'QCS.CheckIndividualstore',
    description: 'QCS.CheckIndividualstoreDescription',
    onClickHandler: (searchValue: string) => {
      // Implement the logic for the onClickHandler function
      // eslint-disable-next-line no-console
      console.log(searchValue);
    },
  },
  {
    title: 'QCS.HistoryScanLog',
    description: 'QCS.HistoryScanLogDescription',
    onClickHandler: (searchValue: string) => {
      // Implement the logic for the onClickHandler function
      // eslint-disable-next-line no-console
      console.log(searchValue);
    },
  },
];

export const shiftGoalDetails = [
  {
    title: 'Totes',
    progress: 75,
    target: 100,
  },
  {
    title: 'Pallets',
    progress: 40,
    target: 50,
  },
  {
    title: 'DSD',
    progress: 8,
    target: 10,
  },
  {
    title: 'Returns',
    progress: 15,
    target: 20,
  },
];

export const myProgressDetails = [
  {
    title: 'PAB',
    progress: 75,
  },
  {
    title: 'PAC',
    progress: 75,
  },
  {
    title: 'PAD',
    progress: 75,
  },
  {
    title: 'PAE',
    progress: 75,
  },
  {
    title: 'PAF',
    progress: 75,
  },
  {
    title: 'PAG',
    progress: 75,
  },
  {
    title: 'PBB',
    progress: 75,
  },
  {
    title: 'PBC',
    progress: 75,
  },
  {
    title: 'PBD',
    progress: 75,
  },
  {
    title: 'PBE',
    progress: 75,
  },
  {
    title: 'PBF',
    progress: 75,
  },
  {
    title: 'PBI/J',
    progress: 75,
  },
];

export const sampleData: TopVarianceProductCategoryType[] = [
  { productCategory: 'Alternators', dollarVariances: '$255.00' },
  { productCategory: 'Drain Train', dollarVariances: '$200.00' },
  { productCategory: 'Spark Plugs', dollarVariances: '$104.23' },
  { productCategory: 'Heating and Colling', dollarVariances: '$40.00' },
  { productCategory: 'Ignition Products', dollarVariances: '$35.00' },
  { productCategory: 'Bearings and Seals', dollarVariances: '$255.00' },
  { productCategory: 'Engine Management', dollarVariances: '$200.00' },
  { productCategory: 'Gaskets', dollarVariances: '$104.23' },
  { productCategory: 'Transmission Parts', dollarVariances: '$40.00' },
  { productCategory: 'Filters', dollarVariances: '$35.00' },
  { productCategory: 'Alternators', dollarVariances: '$255.00' },
  { productCategory: 'Drain Train', dollarVariances: '$200.00' },
  { productCategory: 'Heating and Colling', dollarVariances: '$104.23' },
  { productCategory: 'Gaskets', dollarVariances: '$40.00' },
  { productCategory: 'Engine Management', dollarVariances: '$35.00' },
  { productCategory: 'Alternators', dollarVariances: '$255.00' },
];
